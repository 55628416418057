import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  OIDC_CLIENT_ID,
  OIDC_DOMAIN,
  USE_ZITADEL,
} from "./env"

import { useCallback } from "react"

import { Outlet, useNavigate } from "react-router"

import { useAuth0 } from "@auth0/auth0-react"
import { useZitadel } from "./zitadel"

import type { AppState as Auth0AppState } from "@auth0/auth0-react"

import { Auth0Provider } from "@auth0/auth0-react"
import type { AppState as ZitadelAppState } from "./zitadel/zitadel-provider"
import ZitadelProvider from "./zitadel/zitadel-provider"

const useAuth = () => {
  const auth0 = useAuth0()
  const zitadel = useZitadel()

  return USE_ZITADEL && zitadel ? zitadel : auth0
}

export const AuthWrapper: React.FC = () => {
  const navigate = useNavigate()
  const onRedirectCallback = useCallback(
    (appState?: Auth0AppState | ZitadelAppState) => {
      void navigate(appState?.returnTo || window.location.pathname)
    },
    [navigate]
  )

  const zitadelProvider = (
    <ZitadelProvider
      onRedirectCallback={onRedirectCallback}
      authority={OIDC_DOMAIN}
      client_id={OIDC_CLIENT_ID}
      redirect_uri={window.location.origin}
      scope="openid profile email offline_access urn:zitadel:iam:user:resourceowner"
    >
      <Outlet />
    </ZitadelProvider>
  )

  const auth0provider = (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUTH0_AUDIENCE,
      }}
      cacheLocation="localstorage"
    >
      <Outlet />
    </Auth0Provider>
  )

  return USE_ZITADEL ? zitadelProvider : auth0provider
}

export default useAuth

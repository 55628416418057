import { CircularProgress, Divider, styled, Typography } from "@mui/material"
import { Fragment, useMemo } from "react"
import { MapProvider } from "react-map-gl/maplibre"
import { ENV } from "../../utils/env"
import { extraColors } from "../../utils/theme"
import { AskBibliography } from "./AskBibliography"
import { AskCharts } from "./AskCharts"
import { AskCopy } from "./AskCopy"
import type { AskHistoryEntry } from "./askDB"
import { AskFinePrint } from "./AskFinePrint"
import { ASK_CONTAINER_QUERY_WIDTH } from "./AskHistory"
import { AskMap } from "./AskMap"
import { AskSources } from "./AskSources"
import { AskGradientBorder } from "./AskUI"
import { processAnswerContent } from "./askUtils"
import { useIsAsking } from "./useAskApi"
import { UserFeedback } from "./UserFeedback/UserFeedback"

export const AskLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.tiny,
  color: extraColors.medium,
  display: "block",
  paddingBottom: 2,
  textTransform: "uppercase",
})) as typeof Typography

const ResultContainer = styled("div")(({ theme }) => ({
  borderRadius: 2,
  display: "grid",
  alignContent: "start",
  gap: 24,
  overflow: "auto",
  height: "100%",
  padding: 0,
  background: extraColors.white,
  [theme.containerQueries.up(ASK_CONTAINER_QUERY_WIDTH)]: {
    background: extraColors.subtle,
    padding: 24,
  },
}))

const ResponseContainer = styled(AskGradientBorder)(({ theme }) => ({
  padding: 12,
  display: "grid",
  gap: 12,
  "& p": {
    ...theme.typography.body2,
    margin: 0,
  },
}))

export const AskResult = ({
  selectedHistory,
}: {
  selectedHistory: AskHistoryEntry | undefined
}) => {
  const queryId = selectedHistory?.ragResponse?.rag_context.query_id
  const { answerContent, sources, sups } = useMemo(
    () =>
      processAnswerContent(
        selectedHistory?.ragResponse?.rag_context.answer ?? ""
      ),
    [selectedHistory?.ragResponse?.rag_context.answer]
  )
  const isAsking = useIsAsking()

  if (isAsking) return <AskLoading />
  if (!selectedHistory) return <NoResults />

  return (
    <ResultContainer>
      <AskCopy query={selectedHistory.ragResponse?.rag_context.query}>
        <div>
          <AskLabel component="h3">Question</AskLabel>
          <Typography variant="body2">
            {selectedHistory.ragResponse?.rag_context.query}
          </Typography>
        </div>
        <div>
          <AskLabel component="h3">Response</AskLabel>
          <ResponseContainer data-fab-anchor>
            <div
              dangerouslySetInnerHTML={{
                __html: answerContent,
              }}
            />
            <AskSources sources={sources} sups={sups} />
          </ResponseContainer>
        </div>
        <AskFinePrint />
      </AskCopy>
      {queryId && <UserFeedback queryId={queryId} />}
      {/* Optional charts */}
      <MapProvider>
        {selectedHistory.tabularResponse?.map(tabularData => (
          <Fragment
            key={tabularData.diseaseCode + tabularData.type + tabularData.unit}
          >
            {ENV === "sbx" && <AskMap tabularData={tabularData} />}
            <AskCharts tabularData={tabularData} />
            <Divider />
          </Fragment>
        ))}
      </MapProvider>
      {selectedHistory.ragResponse?.data.length && (
        <AskBibliography
          ragResponse={selectedHistory.ragResponse}
          sups={sups}
        />
      )}
    </ResultContainer>
  )
}

const AskLoading = () => (
  <ResultContainer sx={{ alignContent: "center", padding: "24px" }}>
    <CircularProgress
      style={{
        justifySelf: "center",
        alignSelf: "center",
        color: extraColors.purpleMiddle,
      }}
    />
  </ResultContainer>
)

const NoResults = () => (
  <ResultContainer>
    <Typography variant="body2">No results to display</Typography>
  </ResultContainer>
)

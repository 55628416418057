import type React from "react"
import { useLocation } from "react-router"
import { MainContent } from "../components/MainContent"
import { NotFoundContent } from "../components/PageNotFound"
import { usePermissions } from "../hooks/usePermissions"
import { useSearchParams } from "../hooks/useSearchParams"
import { useUser } from "../hooks/useUser"
import useAuth from "../utils/auth"

export const NotFound: React.FC = () => {
  const location = useLocation()
  const params = useSearchParams()
  const auth = useAuth()
  const user = useUser()
  const permissions = usePermissions()
  console.error({ location, params, auth, user, permissions })
  return (
    <>
      <MainContent
        headerProps={{
          title: "Page Not Found",
        }}
      >
        <NotFoundContent />
      </MainContent>
    </>
  )
}

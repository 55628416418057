import { styled, useMediaQuery, useTheme } from "@mui/material"
import type {
  Map as CurrentMap,
  GeoJSONFeature,
  MapEvent,
  MapMouseEvent,
} from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import type React from "react"
import type { ReactNode, RefObject } from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import { GeolocateControl, Map, NavigationControl } from "react-map-gl/mapbox"

import {
  MAPBOX_STYLE,
  MapSourceLayers,
  determineSourceLayerContext,
  findFeatureBoundingBox,
  isPhcFeature,
  mapboxConfig,
} from "@phc/common"
import type { MapRef } from "react-map-gl/mapbox"

import { useMapContext } from "../../../contexts/MapContext"
import { BORDER_RADIUS, FONT_FAMILY, extraColors } from "../../../utils/theme"
import { LayerFilterButton } from "../../LayerFilterControls/LayerFilterButton"
import { getIndicatorStyles } from "../../LocationDetails/LocationRisk/SeverityVisualIndicator"
import { AlertDrawer } from "../Drawers/Alerts/AlertDrawer"
import { Locations } from "../Drawers/Locations/Locations"

import type { Bucket, Disease_Type } from "@phc-health/connect-query"
import { bboxPolygon } from "@turf/bbox-polygon"
import {
  useSearchParams,
  type SetSearchParams,
} from "../../../hooks/useSearchParams"
import { MAPBOX_ACCESS_TOKEN } from "../../../utils/env"
import { GlobalAlerts } from "../Global/GlobalAlerts"
import { getMapBreakpoint } from "../Shared"
import { AlertMarkers } from "./AlertMarkers"
import { ASSET_SOURCE_ID, AssetMarkers } from "./AssetMarkers"
import { isAssetGeoJson } from "./hooks/useAssetGeoJson"
import { MapLegend } from "./MapLegend"
import { ReactMapboxSearch } from "./ReactMapBoxSearch"
import { ReactMapRiskPopup } from "./ReactMapRiskPopup"

// import syntax for SVG URLs has been busted since Vite 5 --> https://github.com/vitejs/vite/issues/15208#issuecomment-1849555111
// https://vitejs.dev/guide/assets.html#new-url-url-import-meta-url
const minus = new URL(
  "../../../assets/svgs/minus.svg#icon",
  import.meta.url
).toString()
const plus = new URL(
  "../../../assets/svgs/plus.svg#icon",
  import.meta.url
).toString()
const locationArrow = new URL(
  "../../../assets/svgs/location-arrow.svg",
  import.meta.url
).toString()

export enum MapType {
  MAP = "MAP",
  HOME = "HOME",
  MINI = "MINI",
}

const MapboxContainer = styled("div")<{ open: boolean; type: MapType }>(
  ({ theme, open, type }) => ({
    height: type === MapType.MINI ? "206px" : "100%",
    width: "100%",
    borderRadius: type === MapType.MINI ? 20 : 0,
    overflow: "hidden",
    position: "relative",
    fontFamily: FONT_FAMILY,
    "& .mapboxgl-marker": {
      cursor: "pointer",
    },
    // Hide these mapbox controls
    "& .mapboxgl-ctrl.mapboxgl-ctrl-attrib, .mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-compass":
      {
        display: "none",
      },
    "& .mapboxgl-ctrl-group": {
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      color: extraColors.white,
      display: type === MapType.MINI ? "none" : "grid",
      gap: "10px",
    },
    "& .mapboxgl-ctrl-geocoder--button": {
      background: "transparent",
    },
    "& .mapboxgl-popup ": {
      zIndex: 2,
    },
    "& .mapboxgl-popup-content ": {
      padding: 0,
      lineHeight: "12px",
      fontFamily: FONT_FAMILY,
    },

    "& .mapboxgl-ctrl-geocoder--icon": {
      fill: extraColors.navy,
    },

    "& .mapboxgl-ctrl-group button": {
      marginRight: mapMargins(type, open, false),
      border: `1px solid ${extraColors.navy}`,
      backgroundColor: extraColors.white,
      width: 38,
      height: 38,
      [getMapBreakpoint(theme)]: {
        marginRight: mapMargins(type, open, true),
      },
      "&:hover": {
        backgroundColor: extraColors.white,
      },
    },

    "& .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in": {
      borderRadius: BORDER_RADIUS,
      "& .mapboxgl-ctrl-icon": {
        backgroundImage: `url(${plus})`,
      },
      [getMapBreakpoint(theme)]: {
        display: "none",
      },
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out": {
      borderRadius: BORDER_RADIUS,
      "& .mapboxgl-ctrl-icon": {
        backgroundImage: `url(${minus})`,
      },
      [getMapBreakpoint(theme)]: {
        display: "none",
      },
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate": {
      borderRadius: BORDER_RADIUS,
      width: 35,
      marginTop: 10,
      marginLeft: 12,
      marginRight: mapMargins(type, open, false),
      [getMapBreakpoint(theme)]: {
        marginRight: mapMargins(type, open, true),
        marginBottom: 50,
      },
      "& .mapboxgl-ctrl-icon": {
        backgroundImage: `url(${locationArrow})`,
      },
    },
    "& .mapboxgl-ctrl-geocoder--input": {
      color: extraColors.dark,
      border: "1px solid white",
      borderRadius: BORDER_RADIUS,
      backgroundColor: "transparent",
      "&:not(:focus)": {
        padding: 0,
        transform: "translateX(0)",
        opacity: 0,
        ".mapboxgl-ctrl-geocoder--icon-close": {
          display: "none",
        },
      },
      "&::placeholder": {
        opacity: 0,
      },
    },
    "& .mapboxgl-ctrl-geocoder.mapboxgl-ctrl": {
      [theme.breakpoints.down("md")]: {
        marginTop: 60,
      },
      backgroundColor: extraColors.white,
      color: extraColors.navy,
      borderRadius: BORDER_RADIUS,
      border: `1px solid ${extraColors.navy}`,
      height: 38,
      // TODO fix close button that shows up in the the wrong place
      "&:not(:focus-within)": {
        width: 38,
        minWidth: 0,
        // hack around a click race condition!
        transitionDelay: "100ms, 100ms",
        ".mapboxgl-ctrl-geocoder--icon-close": {
          display: "none",
        },
      },
      "& .mapboxgl-ctrl-geocoder--icon-search": {
        fill: extraColors.navy,
        height: 22,
        width: 22,
        top: 6,
        left: 6,
      },
    },
    "@media print": {
      display: "none",
    },
    "& .mapboxgl-map": {
      font: "inherit",
    },
  })
)

const MapboxChildren = styled("div")(({ theme }) => ({
  pointerEvents: "none",
  position: "absolute",
  bottom: 0,
  right: 0,
  top: 0,
  display: "grid",
  gridAutoFlow: "column",
  width: "100%",
  justifyContent: "flex-end",
  [getMapBreakpoint(theme)]: {
    top: "unset",
    left: 0,
    gridAutoFlow: "row",
    height: "100%",
    justifyContent: "unset",
    alignContent: "flex-end",
  },
}))

export interface MapSelectedInfo {
  lat: number
  lng: number
  locationId?: string
  assetId?: string
  isMarker?: boolean
  title?: string
  subtitle?: string
}

export const ReactMapbox: React.FC<{ type: MapType }> = ({ type }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <MapboxMap open={open} type={type}>
        {type === MapType.MAP && (
          <>
            <AlertDrawer />
            <Locations open={open} setOpen={setOpen} />
          </>
        )}
      </MapboxMap>
    </>
  )
}

const MapboxMap: React.FC<{
  type: MapType
  open: boolean
  children?: ReactNode
}> = ({ type, open, children }) => {
  const { setSearchParams } = useSearchParams()

  const hoverFeature = useRef<GeoJSONFeature | null>(null)
  const { bucketData, diseaseType, mapLoadedRef } = useMapContext()
  const [firstSymbolId, setFirstSymbolId] = useState<string | undefined>()

  const selectedFeature = useRef<GeoJSONFeature | null>(null)

  const onMouseMove = useCallback(
    (event: MapMouseEvent) => {
      handleMouseMove(event, hoverFeature, selectedFeature)
    },
    [hoverFeature]
  )

  const bucketDataRef = useRef(bucketData)
  const diseaseTypeRef = useRef(diseaseType)
  // React data gets stale inside of mapbox event handlers, so we need to use refs
  useEffect(() => {
    bucketDataRef.current = bucketData
    diseaseTypeRef.current = diseaseType
  }, [bucketData, diseaseType])

  const mapLoaded = (map: MapEvent) => {
    handleMapLoaded({
      map: map.target,
      setFirstSymbolId,
      bucketData: bucketDataRef.current,
      disease: diseaseTypeRef.current,
    })
    mapLoadedRef.current = true
  }

  const onMapClick = (e: MapMouseEvent) => {
    handleMapClick({
      e,
      setSearchParams,
      prevSelectedFeatureRef: selectedFeature,
    })
  }

  // Keep map feature state up to date with disease type changes
  const mapRef = useRef<MapRef>(null)
  useEffect(() => {
    if (mapRef.current)
      setMapFeatureState({
        map: mapRef.current.getMap(),
        bucketData: bucketDataRef.current,
        disease: diseaseTypeRef.current,
      })
  }, [diseaseType, bucketData])

  return (
    <>
      <MapboxContainer open={open} type={type}>
        <Map
          ref={mapRef}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          mapStyle={MAPBOX_STYLE}
          testMode={import.meta.env.MODE === "test"}
          onLoad={mapLoaded}
          onClick={onMapClick}
          onMouseMove={onMouseMove}
          onRemove={() => {
            mapLoadedRef.current = false
          }}
          dragRotate={false}
          interactive
          dragPan
          touchZoomRotate
          scrollZoom
          doubleClickZoom
          initialViewState={{
            latitude: 39.8283,
            longitude: type === MapType.MAP ? -66 : -92.5795,
            zoom: 2,
            bearing: 0,
          }}
          reuseMaps
        >
          <MapSourceLayers firstSymbolId={firstSymbolId} />

          <ReactMapRiskPopup />

          {/* <ReactMapSelectController /> */}
          <MapControls bucketData={bucketData} type={type} />
          <AlertMarkers />
          <AssetMarkers />
          <MapboxChildren>
            <MapLegend />
            <GlobalAlerts />
            {children}
          </MapboxChildren>
        </Map>
      </MapboxContainer>
    </>
  )
}

const MapControls: React.FC<{
  bucketData: Record<string, Bucket[]>
  type: MapType
}> = ({ bucketData: bucketData }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      <ReactMapboxSearch position="top-left" bucketData={bucketData} />
      <LayerFilterButton />
      <GeolocateControl position={isMobile ? "top-left" : "bottom-right"} />
      <NavigationControl />
    </>
  )
}

const handleMouseMove = (
  event: MapMouseEvent,
  prevHoverFeatureRef: RefObject<GeoJSONFeature | null>,
  selectedLocationRef?: RefObject<GeoJSONFeature | null>
) => {
  const map = event.target
  const selectedLocation = selectedLocationRef?.current
  const prevHoverFeature = prevHoverFeatureRef.current
  // Set opacity of the first non-label feature with an id
  const feature = map
    .queryRenderedFeatures([event.point.x, event.point.y])
    .find(f => {
      try {
        return (
          f.source !== ASSET_SOURCE_ID &&
          f.properties?.location_code &&
          !f.sourceLayer?.includes("label") &&
          !f.sourceLayer?.includes("line") &&
          !f.layer?.id.includes("highlight")
        )
      } catch (e) {
        console.log(f)
        console.error("Error filtering hovered features", e)
      }
    })

  prevHoverFeatureRef.current = feature ?? null

  // TODO: I think these ids need to be changed?
  // Don't fiddle with a selected feature
  if (
    prevHoverFeature?.properties?.location_code ===
    selectedLocation?.properties?.location_code
  ) {
    return
  }
  // Reset the feature state hover
  if (
    prevHoverFeature &&
    prevHoverFeature.properties?.location_code !==
      feature?.properties?.location_code
  ) {
    map.setFeatureState(prevHoverFeature, { isActive: false })

    if (
      // @ts-expect-error for debugging boundary bounding boxes
      window.bbox &&
      map.isSourceLoaded(
        `bbox-${prevHoverFeature.properties?.location_code ?? ""}`
      )
    ) {
      map.removeLayer(
        `bbox-${prevHoverFeature.properties?.location_code ?? ""}`
      )
      map.removeSource(
        `bbox-${prevHoverFeature.properties?.location_code ?? ""}`
      )
    }
  }

  if (feature) {
    // Set the opacity of the feature under the mouse to 0.7
    map.setFeatureState(feature, {
      isActive: true,
    })

    const bboxId = `bbox-${feature.properties?.location_code ?? ""}`
    // @ts-expect-error for debugging boundary bounding boxes
    if (window.bbox && !map.isSourceLoaded(bboxId)) {
      const bbox = findFeatureBoundingBox(feature)?.toArray().flat() as [
        number,
        number,
        number,
        number,
      ]
      const box = bboxPolygon([bbox[0], bbox[1], bbox[2], bbox[3]])
      map.addSource(bboxId, {
        type: "geojson",
        data: box,
      })
      map.addLayer({
        id: bboxId,
        type: "fill",
        source: bboxId, // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0080ff", // blue color fill
          "fill-opacity": 0.5,
        },
      })
    }
  }

  map.getCanvas().style.cursor = feature ? "pointer" : ""
}

interface SetMapFeatureStateProps {
  map: CurrentMap
  bucketData: Record<string, Bucket[]>
  disease: Disease_Type
}

const setMapFeatureState = ({
  map,
  bucketData: bucketData,
  disease: diseaseType,
}: SetMapFeatureStateProps) => {
  mapboxConfig.allBySource.forEach((source, key) => {
    Object.entries(bucketData).forEach(([locationId, buckets]) => {
      // only set feature state for risk indexes that match the current layer
      // performance optimization that took this from 200ms to 20ms
      if (
        key !== buckets[0]?.baseEvent?.geotags[0]?.countryCode.toLowerCase()
      ) {
        return
      }

      const currentBucket = buckets.find(b => b.disease === diseaseType)
      const idContext = determineSourceLayerContext(locationId)
      const layer = source.find(l => l.context === idContext)

      if (!layer) {
        console.error(
          `No layer found for locationId ${locationId} and context ${
            idContext ?? ""
          }`
        )
        return
      }

      if (!map.getSource(layer.sourceInfo.source)) {
        return
      }
      map.setFeatureState(
        {
          source: layer.sourceInfo.source,
          sourceLayer: layer.id,
          id: parseInt(locationId),
        },
        {
          fillColor: getIndicatorStyles(
            diseaseType ? currentBucket?.severity : undefined
          ).background,
        }
      )
    })
  })
}

const handleMapLoaded = ({
  map,
  setFirstSymbolId,
  bucketData: bucketData,
  disease: diseaseType,
}: SetMapFeatureStateProps & {
  setFirstSymbolId: (id?: string) => void
}) => {
  // @ts-expect-error for debugging
  window.map = map
  setMapFeatureState({
    map,
    bucketData: bucketData,
    disease: diseaseType,
  })
  const allMapboxLayers = map.getStyle()?.layers
  // Find the index of the first symbol layer in the map style.
  if (allMapboxLayers?.length) {
    for (const layer of allMapboxLayers) {
      if (layer.type === "symbol") {
        setFirstSymbolId(layer.id)
        break
      }
    }
  }
}

const handleMapClick = ({
  e,
  setSearchParams,
  prevSelectedFeatureRef,
}: {
  e: MapMouseEvent
  setSearchParams: SetSearchParams
  prevSelectedFeatureRef: RefObject<GeoJSONFeature | null>
}) => {
  const map = e.target
  const prevSelectedFeature = prevSelectedFeatureRef.current
  if (
    prevSelectedFeature?.properties?.location_code &&
    prevSelectedFeature.id
  ) {
    map.setFeatureState(prevSelectedFeature, {
      isActive: false,
    })
  }
  const allFeatures = map.queryRenderedFeatures([e.point.x, e.point.y])
  // find relevant features
  const clickedFeature = allFeatures.find(
    feature =>
      (feature.sourceLayer &&
        !feature.sourceLayer.includes("label") &&
        !feature.sourceLayer.includes("line") &&
        !feature.layer?.id.includes("highlight")) ||
      feature.source === ASSET_SOURCE_ID
  )
  prevSelectedFeatureRef.current = clickedFeature ?? null
  const isAssetMarker = clickedFeature?.source === ASSET_SOURCE_ID
  if (isAssetMarker && isAssetGeoJson(clickedFeature)) {
    setSearchParams({
      id: clickedFeature.properties.location_code,
      assetId: clickedFeature.properties.id,
    })
    return
  }
  if (
    !isPhcFeature(clickedFeature) ||
    !clickedFeature.properties.location_code
  ) {
    console.log("No clickable feature found.")
    // clear selected info to close popup
    setSearchParams(null)
    return
  }

  map.setFeatureState(clickedFeature, {
    isActive: true,
  })

  setSearchParams({
    id: clickedFeature.properties.location_code,
    centroid: clickedFeature.properties.centroid,
  })

  e.originalEvent.stopPropagation()
}

const mapMargins = (type: MapType, open: boolean, isMobile: boolean) => {
  if (type === MapType.MAP) {
    if (isMobile) {
      return 20
    }
    return open ? 380 : 55
  }

  return 0
}

import type { StyleSpecification } from "maplibre-gl"
import { MAP_ASSETS_URL } from "../../utils/constants"
import maputnikStyle from "./map-style.json"
export const mapStyle = {
  version: 8,
  name: "PHC Basemap",
  metadata: {},
  sources: {
    protomaps: {
      type: "vector",
      url: `pmtiles://${MAP_ASSETS_URL}/planet_20240520.pmtiles`,
    },
  },
  sprite: `${MAP_ASSETS_URL}/basemaps-assets-main/sprites/v3/black`,
  glyphs: `${MAP_ASSETS_URL}/basemaps-assets-main/fonts/{fontstack}/{range}.pbf`,

  // Load layer styles that can be edited by maputnik
  layers: maputnikStyle.layers as StyleSpecification["layers"],
} satisfies StyleSpecification

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  styled,
  TextField,
  Typography,
} from "@mui/material"
import type React from "react"
import { useCallback, useMemo, useState } from "react"
import { trackEvent } from "../../../utils/mixpanel"
import { extraColors } from "../../../utils/theme"
import { useCreateUserFeedback } from "../useUserFeedback"

const OTHER_OPTION = "Other"
const DEFAULT_FEEDBACK_OPTION_STATE = new Map<string, boolean>([
  ["Contains factual errors", false],
  ["Does not answer the question", false],
  ["Lacks actionable information", false],
  [OTHER_OPTION, false],
])

const NegativeFeedbackContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundColor: extraColors.white,
  width: "100%",
  borderRadius: 2,
  padding: 12,
})

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 12,
})

export const NegativeFeedbackSection: React.FC<{
  setIsHelpful: (val?: boolean) => void
  queryId: string
}> = ({ setIsHelpful, queryId }) => {
  const [feedbackOptionsByName, setFeedbackOptionsByName] = useState(
    DEFAULT_FEEDBACK_OPTION_STATE
  )
  const [additionalFeedback, setAdditionalFeedback] = useState<string>()
  const { mutate: createUserFeedback, isPending } = useCreateUserFeedback()

  const clearAllFeedback = useCallback(() => {
    setIsHelpful(undefined)
    setFeedbackOptionsByName(DEFAULT_FEEDBACK_OPTION_STATE)
    setAdditionalFeedback(undefined)
  }, [setIsHelpful])

  // Concatenate together selected options
  const feedbackOptionsForRequest = useMemo(() => {
    return [...feedbackOptionsByName.entries()]
      .filter(([_, val]) => val)
      .map(([text, _]) => text)
      .join(", ")
  }, [feedbackOptionsByName])

  const submitFeedback = useCallback(() => {
    createUserFeedback({
      user_query: queryId,
      response_is_helpful: false, // always false in negative feedback section
      reason: feedbackOptionsForRequest,
      additional_feedback: additionalFeedback,
    })

    trackEvent("CREATE_USER_FEEDBACK", {
      queryId: queryId,
      responseIsHelpful: false,
      reason: feedbackOptionsForRequest,
      additionalFeedback: additionalFeedback,
    })
  }, [
    additionalFeedback,
    createUserFeedback,
    feedbackOptionsForRequest,
    queryId,
  ])

  return (
    <NegativeFeedbackContainer>
      <Typography variant="body2Bold" sx={{ color: extraColors.medium }}>
        We’re sorry you didn’t find this helpful.
      </Typography>
      <Typography variant="body2" sx={{ marginTop: "4px" }}>
        Please let us know why so we can continue to improve this feature.
      </Typography>
      <UserFeedbackReasons
        feedbackOptionsByName={feedbackOptionsByName}
        setFeedbackOptionsByName={setFeedbackOptionsByName}
        setAdditionalFeedback={setAdditionalFeedback}
      />
      {feedbackOptionsByName.get(OTHER_OPTION) && (
        <UserFeedbackAdditionalInfo
          setAdditionalFeedback={setAdditionalFeedback}
          additionalFeedback={additionalFeedback}
        />
      )}
      <NegativeUserFeedbackControls
        submitFeedback={submitFeedback}
        clearAllFeedback={clearAllFeedback}
        isPending={isPending}
      />
    </NegativeFeedbackContainer>
  )
}

const UserFeedbackAdditionalInfo: React.FC<{
  additionalFeedback?: string
  setAdditionalFeedback: (val?: string) => void
}> = ({ additionalFeedback, setAdditionalFeedback }) => {
  return (
    <TextField
      placeholder="Additional feedback (optional)"
      multiline
      value={additionalFeedback}
      onChange={e => setAdditionalFeedback(e.currentTarget.value)}
      rows={3}
      sx={{ marginTop: "4px" }}
      slotProps={{
        htmlInput: {
          style: { fontSize: "14px" },
        },
      }}
    />
  )
}

const UserFeedbackReasons: React.FC<{
  feedbackOptionsByName: Map<string, boolean>
  setFeedbackOptionsByName: (
    value: React.SetStateAction<Map<string, boolean>>
  ) => void
  setAdditionalFeedback: (val?: string) => void
}> = ({
  feedbackOptionsByName,
  setFeedbackOptionsByName,
  setAdditionalFeedback,
}) => {
  const onToggleCheckbox = useCallback(
    (text: string, val: boolean) => {
      setFeedbackOptionsByName(prev => {
        const updated = new Map(prev)
        updated.set(text, !val)
        return updated
      })

      // Clear additional feedback when "Other" is deselected in case text was entered.
      if (text === OTHER_OPTION && val) {
        setAdditionalFeedback(undefined)
      }
    },
    [setAdditionalFeedback, setFeedbackOptionsByName]
  )

  return (
    <FormControl
      sx={{ marginLeft: "2px", marginTop: "8px" }}
      component="fieldset"
      variant="standard"
    >
      {[...feedbackOptionsByName.entries()].map(([text, val]) => {
        return (
          <FormControlLabel
            key={text}
            label={<Typography variant="body2">{text}</Typography>}
            control={
              <Checkbox
                checked={val}
                onChange={() => onToggleCheckbox(text, val)}
              />
            }
          />
        )
      })}
    </FormControl>
  )
}

const NegativeUserFeedbackControls: React.FC<{
  submitFeedback: () => void
  clearAllFeedback: () => void
  isPending: boolean
}> = ({ submitFeedback, clearAllFeedback, isPending }) => {
  return (
    <ButtonContainer>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => clearAllFeedback()}
        disabled={isPending}
      >
        Cancel
      </Button>
      <Button variant="contained" disabled={isPending} onClick={submitFeedback}>
        Submit Feedback
      </Button>
    </ButtonContainer>
  )
}

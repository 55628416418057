import {
  Delete,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  MoreVert,
} from "@mui/icons-material"
import {
  ButtonBase,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material"
import type React from "react"
import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { useContainerQueryUp } from "../../hooks/useContainerQuery"
import { splitIntoChunks, trackEvent } from "../../utils/mixpanel"
import { extraColors } from "../../utils/theme"
import type { AskHistoryEntry, RagResponse } from "./askDB"
import { AskResult } from "./AskResult"
import { CitationPopoverManager } from "./CitationPopover"
import { useAskHistory, useIsAsking } from "./useAskApi"

export const ASK_CONTAINER_QUERY_WIDTH = 680

const Content = styled("div", {
  shouldForwardProp: prop => prop !== "showHistory",
})<{ showHistory: boolean }>(({ theme, showHistory }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: showHistory ? 24 : 0,
  height: "100%",
  overflow: "hidden",
  [theme.containerQueries.up(ASK_CONTAINER_QUERY_WIDTH)]: {
    gridTemplateColumns: showHistory ? "280px 1fr" : "1fr",
  },
}))

const HistoryContainer = styled("div")(() => ({
  width: "100%",
  overflow: "auto",
}))

const HistoryHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 12,
}))

const HistoryEntryButton = styled("div", {
  shouldForwardProp: prop => prop !== "selected",
})<{ selected: boolean }>(({ selected }) => ({
  overflow: "hidden",
  width: "100%",
  display: "flex",
  alignContent: "space-between",
  height: "30px",
  background: selected ? extraColors.purpleSubtle : extraColors.white,
  textAlign: "left",
  fontFamily: "inherit",
  ["&:hover"]: {
    background: extraColors.purpleSubtle,
  },
  // show close button on hover
  "&:hover svg": {
    display: "block",
  },
  position: "relative",
}))

const HistoryEntryText = styled(ButtonBase)(({ theme }) => ({
  ...theme.typography.small1,
  padding: "6px 3px 6px 12px",
  display: "inline",
  textAlign: "left",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}))

const CloseButton = styled(IconButton)(() => ({
  right: 0,
  top: 0,
  padding: 4,
  marginLeft: "auto",
  "& svg": {
    display: "none",
    height: 16,
    width: 16,
  },
}))

const MobileResultContainer = styled("div")(() => ({
  position: "relative",
  top: -8,
}))

const DesktopContainerQuery = styled("div")(() => ({
  display: "block",
  height: "100%",
  overflow: "auto",
}))

const HistoryEntryContainer = styled("div")({
  display: "grid",
  gap: 6,
  marginBottom: 12,
})

export const AskHistory = ({
  pendingAsk,
}: {
  pendingAsk?: AskHistoryEntry
}) => {
  const { isAboveBreakpoint, contentRef } = useContainerQueryUp({
    containerWidth: ASK_CONTAINER_QUERY_WIDTH,
  })
  const [showHistory, setShowHistory] = useState(!pendingAsk)
  const { askHistory } = useAskHistory()
  const [selectedHistoryISO, setSelectedHistoryISO] = useState(pendingAsk?.date)
  const handleSelectHistory = useCallback(
    (date: string | undefined) => {
      setSelectedHistoryISO(date)
      // close history on mobile if a history item is selected
      if (!isAboveBreakpoint) setShowHistory(!date)
    },
    [isAboveBreakpoint]
  )
  const selectedHistory = useMemo(
    () =>
      (selectedHistoryISO &&
        askHistory.find(h => h.date === selectedHistoryISO)) ||
      pendingAsk ||
      // default to most recent query
      askHistory.at(-1),
    [askHistory, pendingAsk, selectedHistoryISO]
  )
  const sortedByDate = useMemo(() => {
    const entries = pendingAsk
      ? [pendingAsk, ...askHistory.toReversed()]
      : askHistory.toReversed()
    const historyByDayMap = Object.groupBy(entries, a =>
      new Date(a.date).toLocaleDateString()
    )
    return Object.entries(historyByDayMap).sort(
      ([a], [b]) => new Date(b).getTime() - new Date(a).getTime()
    )
  }, [askHistory, pendingAsk])

  // Controls the direction of the history arrow
  // On mobile, it will always be left
  // On desktop, it will be right if the history is hidden
  const HistoryArrow =
    showHistory || !isAboveBreakpoint
      ? KeyboardDoubleArrowLeft
      : KeyboardDoubleArrowRight

  // Use History for all desktop
  // Use Back for mobile when history is hidden and result is full screen
  const historyBtnText = isAboveBreakpoint || showHistory ? "History" : "Back"

  return (
    <>
      <CitationPopoverManager selectedHistory={selectedHistory} />
      <Content ref={contentRef} showHistory={showHistory}>
        <HistoryContainer>
          <HistoryHeader
            sx={{
              justifyContent: showHistory ? "space-between" : undefined,
            }}
          >
            <Typography
              variant="body2Bold"
              sx={{
                color: extraColors.medium,
                display: "block",
              }}
            >
              {historyBtnText}
            </Typography>
            <IconButton
              aria-label="toggle-history"
              sx={{ color: extraColors.purpleMiddle }}
              onClick={() => setShowHistory(!showHistory)}
            >
              <HistoryArrow />
            </IconButton>
          </HistoryHeader>
          {!showHistory && (
            <MobileResultContainer>
              {!isAboveBreakpoint && (
                <AskResult selectedHistory={selectedHistory} />
              )}
            </MobileResultContainer>
          )}
          {showHistory &&
            sortedByDate.map(([day, entries]) => (
              <HistoryEntriesByDay
                key={day}
                day={day}
                entries={entries}
                selectedHistory={selectedHistory}
                setSelectedHistoryISO={handleSelectHistory}
              />
            ))}
        </HistoryContainer>
        <DesktopContainerQuery>
          {isAboveBreakpoint && <AskResult selectedHistory={selectedHistory} />}
        </DesktopContainerQuery>
      </Content>
    </>
  )
}

const HistoryEntriesByDay: React.FC<{
  day: string
  entries?: AskHistoryEntry[]
  selectedHistory?: AskHistoryEntry
  setSelectedHistoryISO: (date: string | undefined) => void
}> = ({ day, entries, selectedHistory, setSelectedHistoryISO }) => {
  const isAsking = useIsAsking()

  useEffect(() => {
    if (isAsking) {
      setSelectedHistoryISO(undefined)
    }
  }, [isAsking, setSelectedHistoryISO])

  return (
    <HistoryEntryContainer>
      <Divider sx={{ borderColor: extraColors.light }} />
      <Typography variant="small1" color={extraColors.hint} display="block">
        {day}
      </Typography>
      {entries?.map(({ date, ragResponse: result }) => (
        <HistoryEntry
          key={result?.rag_context.query_id || result?.rag_context.answer} // older items in local storage may not have a query key, so use answer if necessary for unique key
          date={date}
          result={result}
          selectedHistory={selectedHistory}
          setSelectedHistoryISO={setSelectedHistoryISO}
        />
      ))}
    </HistoryEntryContainer>
  )
}

const HistoryEntry: React.FC<{
  date: string
  result?: RagResponse
  selectedHistory?: AskHistoryEntry
  setSelectedHistoryISO: (date: string | undefined) => void
}> = ({ date, result, selectedHistory, setSelectedHistoryISO }) => {
  const isAsking = useIsAsking()

  const onClickHistoryEntry = useCallback(() => {
    setSelectedHistoryISO(date)
    const chunks = splitIntoChunks(result?.rag_context.query ?? "")
    trackEvent("CLICK_ASK_HISTORY", { query: chunks })
  }, [date, result?.rag_context.query, setSelectedHistoryISO])

  return (
    <Fragment>
      <HistoryEntryButton
        selected={!isAsking && selectedHistory?.date === date}
        onClick={onClickHistoryEntry}
      >
        <HistoryEntryText>{result?.rag_context.query}</HistoryEntryText>
        <HistoryEntryMenu
          date={date}
          setSelectedHistoryISO={setSelectedHistoryISO}
        />
      </HistoryEntryButton>
    </Fragment>
  )
}

const HistoryEntryMenu: React.FC<{
  date: string
  setSelectedHistoryISO: (date: string | undefined) => void
}> = ({ date, setSelectedHistoryISO }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setAnchorEl(null)
  }
  const { removeHistoryEntry } = useAskHistory()

  const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    removeHistoryEntry(date).catch(console.error)
    setSelectedHistoryISO(undefined)
    setAnchorEl(null)
  }

  return (
    <>
      <CloseButton
        aria-label="history-menu-button"
        id="history-menu-button"
        aria-controls={open ? "history-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert sx={{ color: extraColors.purpleMiddle }} />
      </CloseButton>
      <Menu
        id="history-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        slotProps={{
          list: {
            "aria-labelledby": "history-menu-button",
          },
          paper: {
            sx: {
              borderRadius: 0,
              boxShadow: "none",
            },
          },
        }}
      >
        <MenuItem
          onClick={handleDelete}
          sx={{
            padding: "12px 6px",
            background: extraColors.status.redSubtle,
            "&:hover": {
              background: extraColors.status.redLight,
            },
          }}
        >
          <ListItemIcon>
            <Delete
              htmlColor={extraColors.status.redDark}
              color="inherit"
              fontSize="small"
            />
          </ListItemIcon>
          <ListItemText
            slotProps={{
              primary: {
                color: extraColors.status.redDark,
              },
            }}
          >
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

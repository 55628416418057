import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { navigatorAxios } from "./useAskApi"

export interface UserFeedbackProps {
  user_query: string
  response_is_helpful: boolean
  reason?: string
  additional_feedback?: string
}

export const useCreateUserFeedback = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (userFeedback: UserFeedbackProps) => {
      return createUserFeedback(userFeedback)
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["userFeedback"] })
    },
  })
}

const createUserFeedback = async (userFeedback: UserFeedbackProps) => {
  const { user_query, response_is_helpful, reason, additional_feedback } =
    userFeedback
  return await navigatorAxios.post(`/rag/user_feedback/`, {
    user_query,
    response_is_helpful,
    reason,
    additional_feedback,
  })
}

const getUserFeedbackById = async (queryId: string) => {
  const response = await navigatorAxios.get<UserFeedbackProps>(
    `/rag/user_feedback/${queryId}/`
  )
  return response.data
}

export const useGetUserFeedbackById = (queryId: string) => {
  return useQuery({
    queryKey: ["userFeedback", queryId],
    queryFn: () => getUserFeedbackById(queryId),
    enabled: !!queryId,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  gridFilteredRowCountSelector,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro"
import type React from "react"
import { TABLE_ROWS } from "../../utils/constants"
import { extraColors } from "../../utils/theme"

const PaginationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  columnGap: 24,
  justifyContent: "space-between",
  width: "100%",
  height: "auto",
  paddingTop: "10px ",
  border: "none",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}))

const ControlsContainer = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  alignItems: "center",
}))

const PageControls = styled(Pagination)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "grid",
    justifyContent: "center",
  },
  "& .MuiPaginationItem-root": {
    ...theme.typography.body2,
    color: extraColors.black,
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    ...theme.typography.body2Bold,
    background: "none",
    letterSpacing: 0.1,
  },
  "& .MuiPaginationItem-root.Mui-selected:hover": {
    background: "none",
  },
  "& .MuiPaginationItem-previousNext": {
    border: `1px solid ${extraColors.disabled}`,
    borderRadius: "8px",
    width: "34px",
    height: "34px",
    color: extraColors.disabled,
    [theme.breakpoints.down("md")]: {
      width: "18px",
      height: "24px",
      border: "none",
    },
  },
}))

const PageSizeSelector = styled(Select)(({ theme }) => ({
  ...theme.typography.small1,
  marginLeft: "15px",
  "& fieldset ": {
    border: 0,
  },

  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
    fontSize: 10,
  },
}))

interface CustomPaginationProps {
  actionButton?: React.ReactNode
}

const CustomPaginationControls: React.FC<CustomPaginationProps> = ({
  actionButton,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const apiRef = useGridApiContext()

  const rowsLength = useGridSelector(apiRef, gridFilteredRowCountSelector)
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const rowMinimum = pageSize || TABLE_ROWS
  const selectedRows = apiRef.current.getSelectedRows()

  if (rowsLength === 0) {
    return null
  }

  const firstItem = page * rowMinimum + 1
  const lastItem = Math.min(page * rowMinimum + rowMinimum, rowsLength)

  return (
    <PaginationContainer>
      <ControlsContainer>
        {actionButton}
        {selectedRows.size > 0 && (
          <Typography
            variant="body2"
            sx={{ marginRight: isMobile ? 0 : 2 }}
            color={extraColors.medium}
          >
            {`${selectedRows.size.toLocaleString()} selected`}
          </Typography>
        )}
      </ControlsContainer>
      <ControlsContainer>
        <Typography
          variant="body2"
          data-testid="row-count"
          sx={{ marginRight: isMobile ? 0 : 2 }}
          color={extraColors.medium}
        >
          {`${firstItem.toLocaleString()} - ${lastItem.toLocaleString()} of ${rowsLength.toLocaleString()} items`}
        </Typography>
        <PageControls
          color="primary"
          count={pageCount}
          page={page + 1}
          onChange={(_, value) => apiRef.current.setPage(value - 1)}
          size={isMobile ? "small" : "medium"}
        />
        <FormControl size={isMobile ? "small" : "medium"}>
          <PageSizeSelector
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pageSize}
            defaultValue={rowMinimum}
            onChange={e => apiRef.current.setPageSize(Number(e.target.value))}
            size="small"
          >
            <MenuItem value={TABLE_ROWS}>{TABLE_ROWS} per page</MenuItem>
            <MenuItem value={25}>25 per page</MenuItem>
            <MenuItem value={50}>50 per page</MenuItem>
          </PageSizeSelector>
        </FormControl>
      </ControlsContainer>
    </PaginationContainer>
  )
}

export default CustomPaginationControls

import type {
  SigninRedirectArgs,
  SigninSilentArgs,
  SignoutRedirectArgs,
  UserProfile,
} from "oidc-client-ts"
import { createContext } from "react"
import type { AuthState } from "./auth-state"
import type { User } from "./zitadel-user"

export interface ZitadelContextInterface<TUser extends User = User>
  extends AuthState<TUser> {
  getAccessTokenSilently: (options?: SigninSilentArgs) => Promise<string>
  getIdTokenClaims: () => Promise<UserProfile | undefined>
  loginWithRedirect: (options?: SigninRedirectArgs) => Promise<void>
  logout: (options?: SignoutRedirectArgs) => Promise<void>
}
const ZitadelContext = createContext<ZitadelContextInterface | null>(null)

export default ZitadelContext

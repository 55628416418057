import { useMemo } from "react"
import type {
  FillLayerSpecification,
  LineLayerSpecification,
} from "react-map-gl/maplibre"
import { Layer, Source } from "react-map-gl/maplibre"
import { MAP_ASSETS_URL } from "../../utils/constants"
import {
  getLayerFromOSMId,
  getLayerZoomLevels,
  useMapMetadata,
  type PMMetadata,
  type PMTileStats,
} from "./askMapUtils"

const askFillPaintConfig = (min: number, max: number) =>
  ({
    // interpolate the fill color based on the count property
    "fill-color": [
      "interpolate-hcl",
      ["linear"],
      ["feature-state", "count"],
      min,
      "#00FF00",
      max,
      "#FF6347",
    ],
    "fill-opacity": [
      "case",
      ["boolean", ["feature-state", "isActive"], false],
      0.7,
      0.4,
    ],
  }) satisfies FillLayerSpecification["paint"]

const askLinePaintConfig = (minzoom: number) =>
  ({
    "line-color": "#687A95",
    "line-width": [
      // The idea here is to make the country and state borders more defined while zoomed in
      // but not super ugly when zoomed out
      "interpolate",
      ["linear"],
      ["zoom"],
      minzoom,
      0.5,
      16,
      [
        "*",
        6, // magic number that looks good
        // if the line is active, make it twice as thick
        ["case", ["boolean", ["feature-state", "isActive"], false], 2, 1],
      ],
    ],
  }) satisfies LineLayerSpecification["paint"]

// may need to bail on the react version, and just add layers manually...
const buildLayers = ({
  firstSymbolId,
  range,
  countByOSM,
  metadata,
}: {
  firstSymbolId: string | undefined
  range?: { min: number; max: number }
  countByOSM?: Record<string, number>
  metadata?: PMMetadata
}) => {
  if (!firstSymbolId || !metadata) {
    return []
  }

  const osmIds = Object.keys(countByOSM ?? {})
  const layersToRender = osmIds
    .map(osmId => getLayerFromOSMId(metadata, osmId))
    .filter(Boolean)
    .reduce<PMTileStats["layers"]>((acc, layer) => {
      if (!acc.some(l => l.layer === layer?.layer)) {
        layer && acc.push(layer)
      }
      return acc
    }, [])

  const layerElements = metadata.tilestats.layers.flatMap(layer => {
    const renderLayer = layersToRender.find(l => l.layer === layer.layer)
    if (!renderLayer) {
      return
    }
    const maxAdminLevel = osmIds.reduce((max, osmId) => {
      const l = getLayerFromOSMId(metadata, osmId)
      return Math.max(
        max,
        l?.attributes?.find(a => a.attribute === "admin_level")?.max ?? 0
      )
    }, 0)
    const { minzoom, maxzoom } = getLayerZoomLevels(
      layer,
      metadata,
      maxAdminLevel
    )
    return [
      <Layer
        id={layer.layer}
        key={layer.layer}
        type="fill"
        source={"all"}
        source-layer={layer.layer}
        paint={askFillPaintConfig(range?.min ?? 0, range?.max ?? 0)}
        minzoom={minzoom}
        maxzoom={maxzoom}
        beforeId={firstSymbolId}
      />,

      <Layer
        id={`${layer.layer}-line`}
        key={`${layer.layer}-line`}
        type="line"
        source="all"
        source-layer={layer.layer}
        paint={askLinePaintConfig(minzoom)}
        minzoom={minzoom}
        maxzoom={22}
        beforeId={firstSymbolId}
      />,
    ]
  })
  return layerElements.filter(Boolean)
}

export const AskMapLayers = ({
  firstSymbolId,
  range,
  countByOSM,
}: {
  firstSymbolId: string | undefined
  range: { min: number; max: number }
  countByOSM: Record<string, number> | undefined
}) => {
  const { data: metadata } = useMapMetadata()
  const layers = useMemo(
    () => buildLayers({ firstSymbolId, range, countByOSM, metadata }),
    [countByOSM, firstSymbolId, metadata, range]
  )

  return (
    <Source
      type="vector"
      url={`pmtiles://${MAP_ASSETS_URL}/all.pmtiles`}
      id="all"
      // Since tabular data is based of of OSM ids, use those instead of PHC ids for now
      promoteId="osm_id"
    >
      {layers}
    </Source>
  )
}

import { callUnaryMethod } from "@connectrpc/connect-query"
import { AccessManagementServiceQuery } from "@phc-health/connect-query"
import { useCallback } from "react"
import { ACCESS_API } from "../utils/constants"
import { USE_ZITADEL } from "../utils/env"
import { useTransport } from "./useTransport"

// TODO: Replace with JWT references
export function useGetAuth0OrgByEmail() {
  if (USE_ZITADEL) {
    throw new Error(
      "useGetAuth0OrgByEmail is not supported when zitadel enabled"
    )
  }
  const transport = useTransport(ACCESS_API)
  return useCallback(
    (userId: string) =>
      callUnaryMethod(
        transport,
        AccessManagementServiceQuery.lookupAuth0Organization,
        { userId }
      ),
    [transport]
  )
}

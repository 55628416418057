import type { GeocoderOptions } from "@mapbox/mapbox-gl-geocoder"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import { isPhcFeature, mapboxConfig, type GeocoderResult } from "@phc/common"
import { useSnackbar } from "notistack"
import type { ControlPosition, MarkerProps } from "react-map-gl/mapbox"
import { useControl } from "react-map-gl/mapbox"

import type { Bucket } from "@phc-health/connect-query"
import { useSearchParams } from "../../../hooks/useSearchParams"
import { MAPBOX_ACCESS_TOKEN } from "../../../utils/env"
import { getFeature } from "../../../utils/helpers"

type GeocoderControlProps = Omit<
  GeocoderOptions,
  "accessToken" | "mapboxgl" | "marker"
> & {
  marker?: boolean | Omit<MarkerProps, "longitude" | "latitude">
  position: ControlPosition
  onLoading?: (e: object) => void
  onResults?: (e: object) => void
  onResult?: (e: object) => void
  onError?: (e: object) => void
  bucketData: Record<string, Bucket[]>
}

const geocodeFilterTypes: MapboxGeocoder.GeocoderOptions["types"] =
  "country,region,postcode,district,place,locality,neighborhood"

export function ReactMapboxSearch({
  marker = false,
  onLoading = noop,
  onResults = noop,
  onResult = noop,
  onError = noop,
  ...props
}: GeocoderControlProps) {
  const { setSearchParams } = useSearchParams()
  const { enqueueSnackbar } = useSnackbar()
  const accessToken = MAPBOX_ACCESS_TOKEN ?? ""
  useControl(
    // following docs: https://github.com/visgl/react-map-gl/blob/master/examples/geocoder/src/geocoder-control.tsx
    () => {
      const ctrl = new MapboxGeocoder({
        ...props,
        marker: !!marker,
        types: geocodeFilterTypes,
        flyTo: false,
        accessToken,
      })
      ctrl.on("loading", onLoading)
      ctrl.on("results", onResults)
      ctrl.on("result", (evt: GeocoderResult) => {
        onResult(evt)

        const { result } = evt
        if (!result?.center) {
          console.error("No center found in geocoder result")
          return
        }
        // make a query to the tilequery to find the correct location
        const [lng, lat] = result.center
        const countryShortCode =
          result.context
            ?.find(
              context => context.id.includes("country") || !!context.short_code
            )
            ?.short_code.split("-")[0]
            ?.toLocaleLowerCase() ??
          // country results don't come back with context, just use properties.short_code
          result.properties.short_code

        //check the mapbox config to see if we have a layer for the country
        if (!mapboxConfig.allBySource.get(countryShortCode)) {
          //if we don't have a layer for the country, clear the search bar and show an error
          ctrl.clear()
          return enqueueSnackbar(
            `Sorry, We don't have data for ${result.place_name}, please try another location.`,
            {
              variant: "error",
            }
          )
        }

        void getFeature({ lng, lat }, accessToken, countryShortCode).then(
          features => {
            // if a region or place only has 1 layer of corresponding mapbox config data,
            // just find the feature bounding box for the country layer since that would
            // mean that we don't want to show regional or place data.
            const useCountryLevelData =
              mapboxConfig.allBySource.get(countryShortCode)?.length === 1

            //sort the features by id
            features.sort(
              (a, b) =>
                a.properties.location_code?.localeCompare(
                  b.properties.location_code ?? ""
                ) ?? 0
            )
            let location
            if (result.id.includes("country") || useCountryLevelData) {
              //if the result is a country, the location is the first feature
              location = features[0]
            } else if (result.id.includes("region")) {
              //if the result is a region, the location is the second feature
              location = features[1]
            } else {
              //location should be the last feature in the array
              location = features[features.length - 1]
            }

            if (location?.properties.location_code) {
              if (!isPhcFeature(location)) {
                console.error("No PHC feature found")
                return
              }
              setSearchParams({
                id: location.properties.location_code,
                fitBounds: true,
                centroid: location.properties.centroid,
              })
            } else {
              console.error("No location found")
            }
          }
        )
      })

      ctrl.on("error", onError)
      return ctrl
    },
    {
      position: props.position,
    }
  )

  return null
}

const noop = () => {} // eslint-disable-line @typescript-eslint/no-empty-function

import type { IdToken } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import type { UserProfile } from "oidc-client-ts"
import useAuth from "../utils/auth"
import { claims, queries } from "../utils/constants"

async function getOrganizationId(
  getIdTokenClaims: () => Promise<UserProfile | IdToken | undefined>
) {
  const tokenClaims = await getIdTokenClaims()
  return tokenClaims?.[claims.org] as string | undefined
}

export function useOrganizationId() {
  const { getIdTokenClaims, isAuthenticated } = useAuth()
  return useQuery({
    queryKey: [queries.organizationId, getIdTokenClaims],
    queryFn: () => getOrganizationId(getIdTokenClaims),
    enabled: isAuthenticated,
  })
}

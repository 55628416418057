import {
  createConnectQueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@connectrpc/connect-query"
import { AssetServiceQuery } from "@phc-health/connect-query"
import { keepPreviousData, useQueryClient } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { useEffect, useMemo } from "react"
import { useTransport } from "../../../hooks/useTransport"
import { ASSET_SERVICE_API } from "../../../utils/constants"
import { getNonGlobalAssets } from "../../../utils/helpers/assetHelper"

const PAGE_SIZE = 500

/**
 * Provide `includeGlobal = true` if you want to include the global asset
 * which contains all of a user's global settings
 */
export const useListAssets = ({
  includeGlobal,
  excludeGroups = false,
  excludeNotifications = false,
  onlyIncludeNotifications = false,
}: {
  includeGlobal: boolean
  excludeGroups?: boolean
  excludeNotifications?: boolean
  onlyIncludeNotifications?: boolean
}) => {
  const transport = useTransport(ASSET_SERVICE_API)
  const query = useInfiniteQuery(
    AssetServiceQuery.listAssets,
    {
      pagination: {
        size: PAGE_SIZE,
      },
      excludeGroups,
      excludeNotifications,
      onlyIncludeNotifications,
    },
    {
      transport,
      gcTime: 1000 * 60 * 60 * 1, // 1 hour
      pageParamKey: "pagination",
      getNextPageParam: (
        lastPage,
        _allPages,
        lastPageParam,
        _allPagesParams
      ) => {
        // stop fetching if we have all the data
        // hasNextPage will be false if we return undefined
        if (lastPage.assets.length < PAGE_SIZE) {
          return undefined
        }
        return {
          size: PAGE_SIZE,
          from: (lastPageParam?.from ?? 0) + PAGE_SIZE,
        }
      },
      placeholderData: prevValue => {
        const data = keepPreviousData(prevValue)
        return data
      },
    }
  )
  const {
    data: pageData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = query

  // Keep fetching until we have all the data
  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().catch(console.error)
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])
  const returnData = useMemo(() => {
    return {
      assets:
        pageData?.pages.flatMap(page =>
          includeGlobal ? page.assets : getNonGlobalAssets(page.assets)
        ) ?? [],
    }
  }, [includeGlobal, pageData?.pages])

  return {
    ...query,
    data: returnData,
  }
}

export const useListAssetGroups = () => {
  const transport = useTransport(ASSET_SERVICE_API)

  return useQuery(
    AssetServiceQuery.listAssetGroups,
    {
      pagination: {
        size: 9999,
      },
    },
    {
      transport,
    }
  )
}

export const useGetAsset = (assetId: string | undefined) => {
  const transport = useTransport(ASSET_SERVICE_API)

  return useQuery(
    AssetServiceQuery.getAsset,
    {
      assetId,
    },
    {
      transport,
      enabled: !!assetId,
    }
  )
}

export const useRemoveAssetGroup = ({
  hideToast = false,
  skipQueryInvalidation = false,
}: { hideToast?: boolean; skipQueryInvalidation?: boolean } = {}) => {
  const { invalidateAssetQueries } = useInvalidateAssetQueries()
  const { enqueueSnackbar } = useSnackbar()
  const successMessage = "Your group has been removed."
  const transport = useTransport(ASSET_SERVICE_API)
  return useMutation(AssetServiceQuery.removeAssetGroup, {
    transport,
    onError: err => {
      !hideToast &&
        enqueueSnackbar("Unable to remove this group.", {
          variant: "error",
        })
      console.error(err)
    },
    retry: 5,
    onSuccess: () => {
      !hideToast &&
        enqueueSnackbar(successMessage, {
          variant: "success",
        })
    },
    onSettled: async () => {
      if (skipQueryInvalidation) return
      await invalidateAssetQueries()
    },
  })
}

export const useInvalidateAssetQueries = () => {
  const queryClient = useQueryClient()

  const invalidateAssetQueries = async () => {
    await queryClient.invalidateQueries({
      queryKey: createConnectQueryKey({
        schema: AssetServiceQuery.listAssets,
        cardinality: "infinite",
      }),
    })

    await queryClient.invalidateQueries({
      queryKey: createConnectQueryKey({
        schema: AssetServiceQuery.getAsset,
        cardinality: "finite",
      }),
    })

    await queryClient.invalidateQueries({
      queryKey: createConnectQueryKey({
        schema: AssetServiceQuery.listAssetGroups,
        cardinality: "finite",
      }),
    })
  }

  return { invalidateAssetQueries }
}

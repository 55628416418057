import type { OAuthError } from "@auth0/auth0-react"
import type { Interceptor } from "@connectrpc/connect"
import { addStaticKeyToTransport } from "@connectrpc/connect-query"
import { createConnectTransport } from "@connectrpc/connect-web"
import { useMemo } from "react"
import useAuth from "../utils/auth"
import type { ApiKey } from "../utils/constants"

const useInterceptors = (): Interceptor[] => {
  const { getAccessTokenSilently } = useAuth()

  return useMemo(
    () => [
      next => async req => {
        try {
          const accessToken = await getAccessTokenSilently()
          req.header.append("Authorization", `Bearer ${accessToken}`)
        } catch (err) {
          const error = err as OAuthError
          if (error.error !== "login_required") {
            console.error(error)
            throw err
          }
        }
        return await next(req)
      },
      /** Delete headers not allowed by phc endpoints */
      next => req => {
        req.header.delete("connect-protocol-version")
        req.header.delete("user-agent")
        return next(req)
      },
    ],
    [getAccessTokenSilently]
  )
}

export const useTransport = (baseUrl: ApiKey) => {
  const interceptors = useInterceptors()
  return useMemo(
    () =>
      addStaticKeyToTransport(
        createConnectTransport({
          baseUrl,
          interceptors,
          useHttpGet: true,
        }),
        baseUrl
      ),
    [baseUrl, interceptors]
  )
}

import type { User } from "./zitadel-user"

export interface AuthState<TUser extends User = User> {
  error?: Error
  isAuthenticated: boolean
  isLoading: boolean
  user?: TUser
}
export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
}

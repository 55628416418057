import DOMPurify from "dompurify"

export const processAnswerContent = (htmlContent: string) => {
  // Sanitize the HTML content
  const sanitizedHTML = DOMPurify.sanitize(htmlContent)
  const parser = new DOMParser()
  const doc = parser.parseFromString(sanitizedHTML, "text/html")

  // Add target="_blank" and rel="noopener noreferrer" to all links
  const links = doc.querySelectorAll("a")
  links.forEach(link => {
    link.setAttribute("target", "_blank")
    link.setAttribute("rel", "noopener noreferrer")
  })

  // Transform <sup> elements into accessible citation triggers
  const sups = Array.from(doc.querySelectorAll("sup"))
  sups.forEach((sup, index) => {
    const supLink = sup.querySelector("a")
    const href = supLink?.getAttribute("href") || ""
    const supNumber = supLink?.textContent || sup.textContent || ""
    const title = supLink?.getAttribute("title") || ""
    const summary = supLink?.getAttribute("data-summary") || ""

    // Create a button that's styled like a sup but accessible
    const button = doc.createElement("button")
    button.setAttribute("type", "button")
    button.setAttribute("class", "citation-trigger")
    button.setAttribute("data-citation-index", supNumber)
    button.setAttribute("data-citation-href", href)
    button.setAttribute("aria-label", `Citation ${index + 1}`)
    button.setAttribute("title", title)
    button.setAttribute("data-summary", summary)
    button.textContent = supNumber

    // Preserve sup styling but make it interactive
    const wrapper = doc.createElement("sup")
    wrapper.appendChild(button)
    sup.parentNode?.replaceChild(wrapper, sup)
  })

  // Deprecated: Remove once sups are fully supported
  // Remove the "Sources" section and return it separately
  const h3Elements = doc.querySelectorAll("h3")
  let sourcesList: HTMLUListElement | null = null
  for (const h3 of h3Elements) {
    if (h3.textContent === "Sources") {
      const nextElement = h3.nextElementSibling
      if (nextElement && ["OL", "UL"].includes(nextElement.tagName)) {
        sourcesList = nextElement as HTMLUListElement | HTMLOListElement
        h3.remove()
        nextElement.remove()
        break
      }
    }
  }

  const answerContent = doc.body.innerHTML
  const sources = sourcesList
    ? Array.from(sourcesList.querySelectorAll("li"))
    : []

  return {
    answerContent,
    /** @deprecated */
    sources,
    sups,
  }
}

import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { ButtonBase, styled } from "@mui/material"
import { convert } from "html-to-text"
import { useSnackbar } from "notistack"
import React, { useRef, type ReactNode } from "react"
import { splitIntoChunks, trackEvent } from "../../utils/mixpanel"
import { extraColors } from "../../utils/theme"
import { ASK_CONTAINER_QUERY_WIDTH } from "./AskHistory"

const FloatingActionBox = styled("div")(({ theme }) => ({
  position: "absolute",
  right: "-13px",
  top: "50%",
  transform: "translateY(-50%)",
  borderRadius: 2,
  background: "white",
  boxShadow: "0px 3px 7px 1px rgba(0, 0, 0, 0.20)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
  width: 26,
  // hide by default until parent gets hovered
  opacity: 0,
  transition: "opacity 0.2s",
  [theme.containerQueries.down(ASK_CONTAINER_QUERY_WIDTH)]: {
    // always show on mobile
    opacity: 1,
    top: "13px",
    right: "4px",
  },
}))

const HoverParent = styled("div")(() => ({
  position: "relative",
  "&:hover .floatingActionBox": {
    opacity: 1,
  },
}))

/**
 * `AskCopy` is a React component that wraps its children and provides a floating action button (FAB)
 * to copy the HTML and plain-text content of the children to the clipboard. The component uses the
 * `useSnackbar` hook to display success or error messages when the copy action is performed.
 *
 * @param {React.PropsWithChildren} props - The props for the component, which include the children to be wrapped.
 *
 * @returns {JSX.Element} The rendered component with a floating action button for copying content.
 *
 * @example
 * ```tsx
 * <AskCopy>
 *   <div data-fab-anchor>
 *     <p>Some content to copy</p>
 *   </div>
 * </AskCopy>
 * ```
 *
 * The component will look for a child element with the `data-fab-anchor` attribute and attach a floating
 * action button to it. When the button is clicked, the content of the child element will be copied to the clipboard.
 */
export const AskCopy = ({
  children,
  query,
}: {
  /** pass in query for analytics tracking only */
  query: string | undefined
} & React.PropsWithChildren) => {
  const { enqueueSnackbar } = useSnackbar()
  const containerRef = useRef<HTMLDivElement>(null)

  const handleCopy = async () => {
    if (!containerRef.current) return

    // Full HTML content (includes formatting, links, etc.)
    const htmlString = containerRef.current.innerHTML
    // Generate plain-text content while preserving line breaks and links
    const textString = convert(htmlString)

    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([htmlString], { type: "text/html" }),
          "text/plain": new Blob([textString], { type: "text/plain" }),
        }),
      ])
      enqueueSnackbar("Copied to clipboard!", { variant: "success" })
      const chunkedQuery = splitIntoChunks(query ?? "")
      trackEvent("CLICK_ASK_COPY", { query: chunkedQuery })
    } catch (err) {
      enqueueSnackbar("Clipboard copy failed", { variant: "error" })
      console.error("Clipboard copy failed:", err)
    }
  }

  // Walk through the children and find the one that has data-fab-anchor
  const processedChildren = React.Children.map(children, child => {
    if (
      React.isValidElement<{ children: ReactNode }>(child) &&
      React.Children.toArray(child.props.children).some(
        c =>
          React.isValidElement<{ "data-fab-anchor": boolean }>(c) &&
          c.props["data-fab-anchor"]
      )
    ) {
      // Wrap just this child in a relative container + attach FAB
      return (
        <HoverParent>
          {child}
          <FloatingActionBox className="floatingActionBox">
            <ButtonBase
              color="primary"
              aria-label="Copy"
              onClick={() => void handleCopy()}
              sx={{
                padding: "6px",
                "&:hover": {
                  backgroundColor: extraColors.purpleSubtle,
                },
                "&:active": {
                  backgroundColor: extraColors.purpleLight,
                },
                "&:focus": {
                  outline: "none",
                  backgroundColor: extraColors.purpleSubtle,
                },
              }}
            >
              <ContentCopyIcon
                sx={{ color: extraColors.purpleMiddle, fontSize: "18px" }}
              />
            </ButtonBase>
          </FloatingActionBox>
        </HoverParent>
      )
    }
    return child
  })

  return (
    // use display: contents to avoid adding an extra div to the DOM
    // while still being able to attach a ref to the children
    // https://developer.mozilla.org/en-US/docs/Web/CSS/display#contents
    <div ref={containerRef} style={{ display: "contents" }}>
      {processedChildren}
    </div>
  )
}

import { Typography, styled } from "@mui/material"
import useAuth from "../../utils/auth"
import { extraColors } from "../../utils/theme"
import { SettingsContainer } from "./shared/SettingsContainer"

const InfoItem = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}))

export const AccountDetails = () => {
  const { user } = useAuth()

  return (
    <SettingsContainer>
      <InfoItem>
        <Typography variant="small1" color={extraColors.medium}>
          Name
        </Typography>
        <Typography variant="body1">{user?.name}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="small1" color={extraColors.medium}>
          Email
        </Typography>
        <Typography variant="body1">{user?.email}</Typography>
      </InfoItem>
    </SettingsContainer>
  )
}

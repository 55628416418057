import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react"

import type {
  SigninRedirectArgs,
  SignoutRedirectArgs,
  User,
} from "oidc-client-ts"
import { initialAuthState } from "./auth-state"
import { loginError, tokenError } from "./errors"
import { reducer } from "./reducer"
import { hasAuthParams } from "./types"
import { createZitadelAuth, type ZitadelConfig } from "./zitadel-client"
import type { ZitadelContextInterface } from "./zitadel-context"
import ZitadelContext from "./zitadel-context"

export interface AppState {
  returnTo?: string
  [key: string]: any
}

export interface ZitadelProviderOptions extends ZitadelConfig {
  children?: React.ReactNode
  onRedirectCallback?: (appState?: AppState, user?: User) => void
  skipRedirectCallback?: boolean
  context?: React.Context<ZitadelContextInterface>
}

const defaultOnRedirectCallback = (appState?: AppState): void => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  )
}
const ZitadelProvider = (opts: ZitadelProviderOptions) => {
  const {
    children,
    skipRedirectCallback,
    onRedirectCallback = defaultOnRedirectCallback,
    context = ZitadelContext,
    ...clientOpts
  } = opts
  const [client] = useState(() => createZitadelAuth(clientOpts))
  const [state, dispatch] = useReducer(reducer, initialAuthState)
  const didInitialize = useRef(false)

  const handleError = useCallback((error: Error) => {
    dispatch({ type: "ERROR", error })
    return error
  }, [])

  useEffect(() => {
    if (didInitialize.current) {
      return
    }
    didInitialize.current = true

    void (async (): Promise<void> => {
      try {
        // on first load, always check if user exists
        let user = await client.userManager.getUser()
        if (user) {
          dispatch({ type: "INITIALIZED", user: user.profile })
          return
        }
        if (hasAuthParams() && !skipRedirectCallback) {
          // callback handler
          user = await client.userManager.signinRedirectCallback()
          dispatch({ type: "INITIALIZED", user: user.profile })
          //TODO: handle redirect callback
          // onRedirectCallback({ returnTo: "/" }, user)
        } else {
          // this is a fresh session, let's authenticate the user
          await client.userManager.signinRedirect()
        }
      } catch (error) {
        handleError(loginError(error))
      }
    })()
  }, [client, onRedirectCallback, skipRedirectCallback, handleError])

  const getIdTokenClaims = useCallback(async () => {
    const user = await client.userManager.getUser()
    return user?.profile
  }, [client])

  const loginWithRedirect = useCallback(
    async (options?: SigninRedirectArgs): Promise<void> => {
      await client.userManager.signinRedirect(options)
    },
    [client]
  )

  const logout = useCallback(
    async (options: SignoutRedirectArgs = {}): Promise<void> => {
      await client.userManager.signoutRedirect(options)
      dispatch({ type: "LOGOUT" })
    },
    [client]
  )

  const getAccessTokenSilently = useCallback(
    async (options?: SigninRedirectArgs): Promise<any> => {
      let token, user
      try {
        user = await client.userManager.getUser()
        if (user) {
          return user.id_token
        }
        await client.userManager.signinRedirect(options)
      } catch (error) {
        throw tokenError(error)
      } finally {
        dispatch({
          type: "GET_ACCESS_TOKEN_COMPLETE",
          user: (await client.userManager.getUser())?.profile,
        })
      }
      return token
    },
    [client]
  )

  const contextValue = useMemo<ZitadelContextInterface>(() => {
    return {
      ...state,
      getAccessTokenSilently,
      getIdTokenClaims,
      loginWithRedirect,
      logout,
    }
  }, [
    state,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
    logout,
  ])

  return <context.Provider value={contextValue}>{children}</context.Provider>
}

export default ZitadelProvider

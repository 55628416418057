import type { AlertLevel } from "@phc/common"
import type { ExpressionSpecification } from "mapbox-gl"
import type React from "react"
import { Layer, Marker, Source } from "react-map-gl/mapbox"
import { AlertClusterMarker } from "./AlertMarker"
import { useAlertCluster } from "./hooks/useAlertCluster"
import { useAlertGeoJson } from "./hooks/useAlertGeoJson"
import { useAlertHighlightSelected } from "./hooks/useAlertHighlightSelected"

export const ALERT_SOURCE_ID = "alert-clusters"
const ALERT_LAYER_ID = "alert-clusters-layer"

const clusterProperties = {
  warning: ["+", ["case", ["==", ["get", "alertLevel"], "warning"], 1, 0]],
  watch: ["+", ["case", ["==", ["get", "alertLevel"], "watch"], 1, 0]],
  advisory: ["+", ["case", ["==", ["get", "alertLevel"], "advisory"], 1, 0]],
  // mapbox-gl expression: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/
} satisfies Record<AlertLevel, ExpressionSpecification>

export const AlertMarkers: React.FC = () => {
  const alertGeoJSON = useAlertGeoJson()
  const { clusterMarkerProps } = useAlertCluster()
  useAlertHighlightSelected()

  if (!alertGeoJSON) return null
  return (
    <>
      <Source
        id={ALERT_SOURCE_ID}
        type="geojson"
        cluster
        clusterProperties={clusterProperties}
        clusterMaxZoom={22}
        clusterRadius={40}
        // filter data by zoom level
        filter={[
          "all",
          ["has", "maxZoom"],
          [">", ["get", "maxZoom"], ["zoom"]],
        ]}
        data={alertGeoJSON}
      >
        <Layer id={ALERT_LAYER_ID} type="fill" source={ALERT_SOURCE_ID} />
      </Source>
      {clusterMarkerProps.map(c => (
        <Marker
          latitude={c.lat}
          longitude={c.lng}
          anchor="center"
          key={`${c.lat}${c.lng}`}
        >
          <AlertClusterMarker alertIds={c.alertIds} />
        </Marker>
      ))}
    </>
  )
}

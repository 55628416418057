import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LinkSharp,
} from "@mui/icons-material"
import { Button, IconButton, Typography } from "@mui/material"
import { DataGridPro, gridClasses, type GridColDef } from "@mui/x-data-grid-pro"
import { useMemo, useState } from "react"
import { dayjsUTC } from "../../utils/helpers"
import { splitIntoChunks, trackEvent } from "../../utils/mixpanel"
import { extraColors } from "../../utils/theme"
import { CONFIDENCE_MAP, ConfidenceBars } from "../Shared/ConfidenceIndicator"
import type { RagDataSource, RagResponse } from "./askDB"

const columns: GridColDef[] = [
  { field: "title", headerName: "Title", flex: 1, minWidth: 200 },
  { field: "publisher", headerName: "Publisher", width: 200 },
  {
    field: "publish_time",
    headerName: "Date",
    type: "date",
    valueFormatter: (_, row) => dayjsUTC(row.publish_time).format("MM/DD/YY"),
  },
  {
    field: "confidence",
    headerName: "Confidence",
    renderCell: params => {
      const score = CONFIDENCE_MAP[params.row.confidence] ?? 1
      return <ConfidenceBars confidence={score} />
    },
  },
  {
    field: "url",
    headerName: "Link",
    type: "actions",
    getActions: () => [],
    width: 60,
    renderCell: params =>
      params.row.url && (
        <IconButton
          href={params.row.url}
          target="_blank"
          rel="noopener noreferrer"
          disableRipple
          sx={{
            color: extraColors.purple,
            "&:hover": {
              color: extraColors.purpleDark,
            },
          }}
        >
          <LinkSharp />
        </IconButton>
      ),
  },
] satisfies GridColDef<RagDataSource>[]

export const AskBibliography = ({
  ragResponse,
  sups,
}: {
  ragResponse: RagResponse
  sups: HTMLElement[]
}) => {
  const [showBibliography, setShowBibliography] = useState(false)
  // unique by url with fallback to title
  const uniqueRagResponse = useMemo(
    () =>
      ragResponse.data.filter(
        (v, i, a) =>
          a.findIndex(t => (t.url ? t.url === v.url : t.title === v.title)) ===
          i
      ),
    [ragResponse.data]
  )
  // get url from href in a tag in sup
  const supUrls = useMemo(
    () =>
      sups
        .map(sup => {
          const anchor = sup.querySelector("a")
          return anchor ? anchor.href : null
        })
        .filter(url => url !== null),
    [sups]
  )
  return (
    <div
      style={{
        // the console shows an error about not having a proper height, but the docs have that same error...
        height: "fit-content",
        width: "100%",
        overflowX: "auto",
      }}
    >
      <Button
        variant="text"
        onClick={() => {
          setShowBibliography(curr => {
            // track event only for opens
            if (!curr) {
              const chunks = splitIntoChunks(ragResponse.rag_context.query)
              trackEvent("OPEN_ASK_BIBLIOGRAPHY", { query: chunks })
            }
            return !curr
          })
        }}
      >
        <Typography
          m={0}
          variant="tinyLink"
          textTransform="uppercase"
          sx={{
            "& svg": {
              fontSize: 20,
              margin: 0,
              verticalAlign: "middle",
            },
          }}
        >
          Additional Resources
          {showBibliography ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Typography>
      </Button>
      {showBibliography && (
        <div>
          <DataGridPro<RagDataSource>
            columns={columns}
            rows={uniqueRagResponse}
            density="compact"
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: "title",
                    sort: "asc",
                  },
                ],
              },
            }}
            sx={t => ({
              border: 0,
              width: "100%",
              background: "white",
              "--DataGrid-containerBackground": "white",
              [`.${gridClasses.columnHeaderTitle}`]: {
                ...t.typography.tiny,
                color: extraColors.medium,
                textTransform: "uppercase",
              },
              [`.${gridClasses.cell}`]: {
                ...t.typography.small1,
                alignContent: "center",
              },
              [`.${gridClasses.row}:hover`]: {
                backgroundColor: extraColors.purpleSubtle,
              },
              [`& .${gridClasses.row}.cited-above *`]: {
                fontWeight: "bold",
              },
              [`.${gridClasses.columnSeparator}`]: {
                display: "none",
              },
            })}
            hideFooter
            getRowClassName={params => {
              if (supUrls.includes(params.row.url)) {
                return "cited-above"
              }
              return ""
            }}
            slots={{
              columnSortedAscendingIcon: ArrowDropUpRounded,
              columnSortedDescendingIcon: ArrowDropDownRounded,
            }}
          />
        </div>
      )}
    </div>
  )
}

import { Box, styled, Typography } from "@mui/material"
import type React from "react"
import { forwardRef, useImperativeHandle, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import { useAppLogo } from "../hooks/useAppLogo"

const PrintContainer = styled(Box)({
  "@media print": {
    /* these properties default background graphics **/
    webKitPrintColorAdjust: "exact",
    colorAdjust: "exact",
    printColorAdjust: "exact",
    margin: "0 20px 20px 20px",
    "@page": {
      size: "8.5in 11in",
      margin: "8mm 0mm 0mm 0mm",
      border: "none !important",
    },
  },
})

export interface PrintHandle {
  handlePrintClick: () => void
}

interface PrintTemplateProps {
  children: React.ReactNode
  legalStatement?: string
}

export const PrintTemplate = forwardRef<PrintHandle, PrintTemplateProps>(
  ({ children, legalStatement }, ref) => {
    const componentRef = useRef<Element | Text>(null)
    const appLogo = useAppLogo()

    const handlePrint = useReactToPrint({
      contentRef: componentRef,
    })

    useImperativeHandle(ref, () => ({
      handlePrintClick() {
        handlePrint()
      },
    }))

    return (
      <PrintContainer ref={componentRef}>
        <Box
          display="none"
          displayPrint="flex"
          justifyContent="center"
          padding={2}
        >
          <img src={appLogo?.full} alt={appLogo?.altText} height={40} />
        </Box>

        {children}

        <Box
          paddingTop={5}
          display={"none"}
          displayPrint={legalStatement && "block"}
        >
          <Typography variant="h3">Confidential and Proprietary</Typography>
          <Typography variant="body2" fontStyle={"italic"}>
            {legalStatement}
          </Typography>
        </Box>
      </PrintContainer>
    )
  }
)

PrintTemplate.displayName = "PrintTemplate"

import { ThumbDown, ThumbUp } from "@mui/icons-material"
import { CircularProgress, IconButton, styled, Typography } from "@mui/material"
import { useFlags } from "launchdarkly-react-client-sdk"
import type React from "react"
import { useState } from "react"
import { trackEvent } from "../../../utils/mixpanel"
import { extraColors } from "../../../utils/theme"
import {
  useCreateUserFeedback,
  useGetUserFeedbackById,
  type UserFeedbackProps,
} from "../useUserFeedback"
import { NegativeFeedbackSection } from "./NegativeFeedback"

const UserFeedbackContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 6,
})

const SimpleFeedbackContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: extraColors.white,
  width: "100%",
  borderRadius: 2,
})

const ThumbUpStyled = styled(ThumbUp)<{ iconcolor: string }>(
  ({ iconcolor }) => ({
    height: 15,
    width: 15,
    color: iconcolor,
  })
)

const ThumbDownStyled = styled(ThumbDown)<{ iconcolor: string }>(
  ({ iconcolor }) => ({
    height: 15,
    width: 15,
    color: iconcolor,
  })
)

export const UserFeedback: React.FC<{ queryId: string }> = ({ queryId }) => {
  const { showNavigatorUserFeedback } = useFlags()

  const { data, isFetching } = useGetUserFeedbackById(queryId)

  if (!showNavigatorUserFeedback) return

  if (isFetching) return <UserFeedbackLoading />

  return <UserFeedbackElements queryId={queryId} userFeedbackResponse={data} />
}

export const UserFeedbackElements: React.FC<{
  queryId: string
  userFeedbackResponse?: UserFeedbackProps
}> = ({ queryId, userFeedbackResponse }) => {
  const { purpleDark, disabled, medium } = extraColors

  const [isHelpful, setIsHelpful] = useState(
    userFeedbackResponse?.response_is_helpful
  )

  const {
    mutate: createUserFeedback,
    isPending,
    isSuccess,
  } = useCreateUserFeedback()

  const buttonDisabled = isHelpful !== undefined || isPending || isSuccess
  const showNegativeFeedbackSection =
    isHelpful === false && !userFeedbackResponse?.user_query

  return (
    <UserFeedbackContainer>
      <SimpleFeedbackContainer>
        <Typography variant="small1" sx={{ color: medium, marginRight: "6px" }}>
          Was this response helpful?
        </Typography>
        <IconButton
          disabled={buttonDisabled}
          onClick={() => {
            createUserFeedback({
              user_query: queryId,
              response_is_helpful: true,
            })
            trackEvent("CREATE_USER_FEEDBACK", {
              queryId: queryId,
              responseIsHelpful: true,
            })
          }}
        >
          <ThumbUpStyled iconcolor={isHelpful ? purpleDark : disabled} />
        </IconButton>
        <IconButton
          disabled={buttonDisabled}
          onClick={() => setIsHelpful(false)}
        >
          <ThumbDownStyled
            iconcolor={isHelpful === false ? purpleDark : disabled}
          />
        </IconButton>
      </SimpleFeedbackContainer>
      {showNegativeFeedbackSection && (
        <NegativeFeedbackSection
          setIsHelpful={setIsHelpful}
          queryId={queryId}
        />
      )}
    </UserFeedbackContainer>
  )
}

const UserFeedbackLoading: React.FC = () => {
  return (
    <SimpleFeedbackContainer sx={{ height: "31px" }}>
      <CircularProgress sx={{ color: extraColors.purpleMiddle }} size={20} />
    </SimpleFeedbackContainer>
  )
}

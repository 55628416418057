export class OAuthError extends Error {
  constructor(
    public error: string,
    public error_description?: string
  ) {
    super(error_description || error)

    Object.setPrototypeOf(this, OAuthError.prototype)
  }
}
const normalizeErrorFn =
  (fallbackMessage: string) =>
  (error: unknown): Error => {
    if (error instanceof Error) {
      return error
    }
    // try to check errors of the following form: {error: string; error_description?: string}
    if (
      error !== null &&
      typeof error === "object" &&
      "error" in error &&
      typeof error.error === "string"
    ) {
      if (
        "error_description" in error &&
        typeof error.error_description === "string"
      ) {
        return new OAuthError(error.error, error.error_description)
      }
      return new OAuthError(error.error)
    }
    return new Error(fallbackMessage)
  }

export const loginError = normalizeErrorFn("Login failed")

export const tokenError = normalizeErrorFn("Get access token failed")

import useAuth from "../utils/auth"
import { Permission } from "../utils/constants"
import { useGetPermissions } from "./useGetPermissions"

export function usePermissions() {
  const { isAuthenticated } = useAuth()
  const { data: permissions, isLoading } = useGetPermissions()
  return {
    isLoading: isAuthenticated && isLoading,
    organizations: {
      read: permissions?.includes(Permission.READ_ORGANIZATION),
      write: permissions?.includes(Permission.WRITE_ORGANIZATION),
      preferences: {
        read: permissions?.includes(
          Permission.READ_PREFERENCES_ORGANIZATION_ALL
        ),
        write: permissions?.includes(
          Permission.WRITE_PREFERENCES_ORGANIZATION_ALL
        ),
      },
      users: {
        read:
          permissions?.includes(Permission.READ_ORGANIZATION) &&
          permissions.includes(Permission.READ_USER),
        write:
          permissions?.includes(Permission.READ_ORGANIZATION) ||
          permissions?.includes(Permission.WRITE_USER),
        preferences: {
          read:
            permissions?.includes(Permission.READ_ORGANIZATION) &&
            permissions.includes(Permission.READ_USER) &&
            permissions.includes(Permission.READ_PREFERENCES_USER_ALL),
          write:
            permissions?.includes(Permission.READ_ORGANIZATION) &&
            permissions.includes(Permission.READ_USER) &&
            permissions.includes(Permission.WRITE_PREFERENCES_USER_ALL),
        },
      },
    },
    currentUser: {
      preferences: {
        read: permissions?.includes(Permission.READ_PREFERENCES_USER_CURRENT),
        write: permissions?.includes(Permission.WRITE_PREFERENCES_USER_CURRENT),
      },
      notifications: {
        read: permissions?.includes(Permission.READ_NOTIFICATIONS),
        write: permissions?.includes(Permission.WRITE_NOTIFICATIONS),
      },
      organization: {
        preferences: {
          read: permissions?.includes(Permission.READ_PREFERENCES_ORGANIZATION),
          write: permissions?.includes(
            Permission.WRITE_PREFERENCES_ORGANIZATION
          ),
        },
        users: {
          read: permissions?.includes(Permission.READ_USER),
          write: permissions?.includes(Permission.WRITE_USER),
          preferences: {
            read:
              permissions?.includes(Permission.READ_USER) &&
              permissions.includes(Permission.READ_PREFERENCES_USER),
            write:
              permissions?.includes(Permission.READ_USER) &&
              permissions.includes(Permission.WRITE_PREFERENCES_USER),
          },
        },
      },
    },
    phcAdminUser: {
      organizations: {
        read: permissions?.includes(Permission.READ_ORGANIZATION),
        write: permissions?.includes(Permission.WRITE_ORGANIZATION),
        users: {
          read:
            permissions?.includes(Permission.READ_ORGANIZATION) &&
            permissions.includes(Permission.READ_USER),
          write:
            permissions?.includes(Permission.READ_ORGANIZATION) &&
            permissions.includes(Permission.WRITE_USER),
        },
      },
    },
    standardAdminUser: {
      organizations: {
        read: permissions?.includes(Permission.READ_ORGANIZATION_CURRENT),
        users: {
          read:
            permissions?.includes(Permission.READ_ORGANIZATION_CURRENT) &&
            permissions.includes(Permission.READ_USER),
          write:
            permissions?.includes(Permission.READ_ORGANIZATION_CURRENT) &&
            permissions.includes(Permission.WRITE_USER),
        },
      },
    },
  }
}

import { useMemo } from "react"
import useAuth from "../utils/auth"
import { claims } from "../utils/constants"

export function useOrganization() {
  const { user } = useAuth()
  return useMemo(() => {
    if (!user) {
      return undefined
    }
    const orgParts = (user[claims.org] as string | undefined)?.split("/")
    const orgName = orgParts?.[1] ?? ""

    return {
      id: user.org_id as string | undefined,
      name: orgName,
    }
  }, [user])
}

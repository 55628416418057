import { GlobalStyles, Popover } from "@mui/material"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { CONFIDENCE_MAP, ConfidenceBars } from "../Shared/ConfidenceIndicator"
import type { AskHistoryEntry } from "./askDB"

export const CitationStyles = (
  <GlobalStyles
    styles={{
      ".citation-trigger": {
        background: "none",
        border: "none",
        padding: 0,
        margin: 0,
        font: "inherit",
        color: "inherit",
        cursor: "pointer",
        textDecoration: "none",
        verticalAlign: "super",
        fontSize: "smaller",
        "&:hover, &:focus": {
          textDecoration: "underline",
          outline: "none",
        },
      },
    }}
  />
)

const CitationContent = styled("div")(() => ({
  padding: 6,
  maxWidth: 220,
  display: "grid",
  gap: 4,
}))

const CitationHeader = styled("div")(() => ({
  display: "grid",
  gap: 6,
  gridTemplateColumns: "auto 1fr",
  justifyContent: "space-between",
  justifyItems: "end",
  alignContent: "space-between",
}))

// New domain display component with favicon
const DomainDisplay = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: 4,
})

const Favicon = styled("img")({
  width: 16,
  height: 16,
  flexShrink: 0,
})

const TruncatedDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.small1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
})) as typeof Typography

const TruncatedDomain = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "160px",
}) as typeof Typography

interface CitationState {
  isOpen: boolean
  anchorEl: HTMLElement | null
  href: string
  domain: string
  title: string
  summary: string
}

export const CitationPopoverManager = ({
  selectedHistory,
}: {
  selectedHistory: AskHistoryEntry | undefined
}) => {
  const [citation, setCitation] = useState<CitationState>({
    isOpen: false,
    anchorEl: null,
    href: "",
    domain: "",
    title: "",
    summary: "",
  })

  useEffect(() => {
    // Use event delegation to handle clicks on citation buttons
    const handleCitationClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement

      if (target.classList.contains("citation-trigger")) {
        e.preventDefault()
        const href = target.getAttribute("data-citation-href") || ""
        const domain = new URL(href).hostname
        const title = target.title
        const summary = target.getAttribute("data-summary") || ""

        setCitation({
          isOpen: true,
          anchorEl: target,
          href,
          domain,
          title,
          summary,
        })
      }
    }

    document.addEventListener("click", handleCitationClick)

    return () => {
      document.removeEventListener("click", handleCitationClick)
    }
  }, [])

  const handleClose = () => {
    setCitation(prev => ({ ...prev, isOpen: false }))
  }
  const confidence = selectedHistory?.ragResponse?.data.find(
    d => d.url === citation.href
  )?.confidence
  const score = CONFIDENCE_MAP[confidence ?? "F"]
  return (
    <>
      {CitationStyles}
      <Popover
        open={citation.isOpen}
        anchorEl={citation.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <CitationContent>
          <CitationHeader>
            <DomainDisplay>
              <Favicon
                src={`https://icons.duckduckgo.com/ip3/${citation.domain}.ico`}
                alt=""
                onError={e => {
                  // Hide the image if it fails to load
                  e.currentTarget.style.display = "none"
                }}
              />
              <TruncatedDomain
                component="a"
                variant="small1"
                title={citation.href}
                href={citation.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {citation.domain}
              </TruncatedDomain>
            </DomainDisplay>
            {score != null && <ConfidenceBars confidence={score} />}
          </CitationHeader>
          <Typography variant="small1Bold">{citation.title}</Typography>
          <TruncatedDescription variant="small1">
            {citation.summary}
          </TruncatedDescription>
        </CitationContent>
      </Popover>
    </>
  )
}

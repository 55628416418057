import { useLayoutEffect, useMemo, useRef, useState } from "react"

/**
 * Custom hook that determines if a container's width is above a specified breakpoint.
 *
 * @param {number} params.containerWidth - The width of the container to compare against.
 * @returns {Object} An object containing:
 * - `contentRef` (React.RefObject<HTMLDivElement>): A ref to be attached to the container element.
 * - `isAboveBreakpoint` (boolean): A boolean indicating if the container's width is above the specified breakpoint.
 *
 * @example
 * const { contentRef, isAboveBreakpoint } = useContainerQueryUp({ containerWidth: 600 });
 *
 * return (
 *   <div ref={contentRef}>
 *     {isAboveBreakpoint ? 'Above 600px' : 'Below 600px'}
 *   </div>
 * );
 */
export const useContainerQueryUp = ({
  containerWidth,
}: {
  containerWidth: number
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(false)
  useLayoutEffect(() => {
    const content = contentRef.current
    if (content) {
      const resizeObserver = new ResizeObserver(() => {
        setIsAboveBreakpoint(content.clientWidth > containerWidth)
      })
      resizeObserver.observe(content)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [containerWidth])
  return useMemo(
    () => ({ contentRef, isAboveBreakpoint }),
    [contentRef, isAboveBreakpoint]
  )
}

import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  styled,
} from "@mui/material"
import { Disease_Type } from "@phc-health/connect-query"
import { useCallback, useState, type ReactNode } from "react"
import { diseaseTypeMapping } from "../../../../utils/helpers/threatTrendHelper"
import { extraColors } from "../../../../utils/theme"
import Button from "../../../Shared/Button"
import { SettingsContainer } from "../../shared/SettingsContainer"
import { useNotificationContext } from "../contexts/NotificationContext"
import { useNotificationTypeContext } from "../contexts/NotificationTypeContext"
import { EmailExampleDialog } from "../EmailExampleDialog"
import { SelectionSummary } from "../ExpertInsights/GlobalSettingsRadioGroup"
import { LocationSelectorDialog } from "../LocationSelector/LocationSelectorDialog"
import { CheckboxContainer, SettingCheckbox } from "../SettingCheckbox"

const ThreatsContainer = styled("div")(() => ({
  padding: "24px 0 0 24px",
  display: "grid",
  gap: "24px",
}))

const SelectAllWrapper = styled("div")(() => ({
  backgroundColor: extraColors.subtle,
  height: "37px",
  display: "flex",
  alignItems: "center",
  paddingLeft: "12px",
}))

const StyledFormLabel = styled(FormControlLabel)(() => ({
  alignItems: "start",
}))

const StyledCheckbox = styled(Checkbox)(() => ({
  padding: 0,
  marginRight: "8px",
  marginTop: "2px",
}))

const CheckboxRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: "start",
  marginLeft: 18,
  [theme.breakpoints.up("md")]: {
    alignSelf: "center",
    marginLeft: 8,
  },
}))

interface ThreatLabel {
  type: Disease_Type
  element: ReactNode
}

const ThreatLabels: ThreatLabel[] = [
  {
    type: Disease_Type.RESP,
    element: (
      <>
        <Typography>{diseaseTypeMapping(Disease_Type.RESP)}</Typography>
        <Typography variant="small1" color={extraColors.medium}>
          Respiratory Illness is based on clinic and hospital visits for
          infectious respiratory illnesses including COVID-19, influenza,
          respiratory syncytial virus (RSV), rhinovirus, adenovirus, and several
          others.
        </Typography>
      </>
    ),
  },
  {
    type: Disease_Type.COVID,
    element: (
      <>
        <Typography>{diseaseTypeMapping(Disease_Type.COVID)}</Typography>
        <Typography variant="small1" color={extraColors.medium}>
          The infectious respiratory disease caused by the SARS-CoV-2 virus.
          Distinct from other coronaviruses, a positive case is confirmed by
          laboratory testing.
        </Typography>
      </>
    ),
  },
  {
    type: Disease_Type.FLU,
    element: (
      <>
        <Typography>{diseaseTypeMapping(Disease_Type.FLU)}</Typography>
        <Typography variant="small1" color={extraColors.medium}>
          The infectious respiratory disease caused by the influenza virus.
          Typically caused by influenza A or B, symptoms include fever, cough,
          sore throat, and achiness.
        </Typography>
      </>
    ),
  },
  {
    type: Disease_Type.RSV,
    element: (
      <>
        <Typography>
          {diseaseTypeMapping(Disease_Type.RSV)} (Respiratory Syncytial Virus)
        </Typography>
        <Typography variant="small1" color={extraColors.medium}>
          The infectious respiratory disease caused by the respiratory syncytial
          virus. Symptoms, which include coughing and fever, tend to be more
          severe in children and the elderly.
        </Typography>
      </>
    ),
  },
]

export const RiskIncreaseNotifications = () => {
  const { notificationType } = useNotificationTypeContext()
  const {
    selectedThreats,
    setSelectedThreats,
    riskIncreaseEnabled,
    setRiskIncreaseEnabled,
    selectedAssets,
    setSelectedAssets,
    nonGlobalAssets,
    setDialogSelectedAssets,
  } = useNotificationContext(notificationType)
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [locationSelectorIsOpen, setLocationSelectorIsOpen] = useState(false)
  const onOpenSelector = useCallback(
    (newState: boolean) => {
      if (newState && !selectedAssets?.length) {
        setSelectedAssets?.(nonGlobalAssets)
        setDialogSelectedAssets?.(nonGlobalAssets)
      }
      setLocationSelectorIsOpen(newState)
    },
    [
      setDialogSelectedAssets,
      nonGlobalAssets,
      selectedAssets?.length,
      setSelectedAssets,
    ]
  )

  return (
    <SettingsContainer title="Risk Increase Notifications">
      <CheckboxRow>
        <SettingCheckbox
          settingEnabled={riskIncreaseEnabled || false}
          customSettingFn={checked => {
            setRiskIncreaseEnabled?.(checked)
            if (!checked) {
              setSelectedAssets?.([])
              setDialogSelectedAssets?.([])
            } else {
              setSelectedAssets?.(nonGlobalAssets)
              setDialogSelectedAssets?.(nonGlobalAssets)
            }
          }}
          title={
            <>
              Receive notifications when the risk level rises in your selected
              locations.
            </>
          }
        />
        <ButtonStyled variant="text" onClick={() => setDialogIsOpen(true)}>
          <Typography variant={"body1Bold"} color={extraColors.purpleMiddle}>
            View example
          </Typography>
        </ButtonStyled>
      </CheckboxRow>
      <EmailExampleDialog
        dialogIsOpen={dialogIsOpen}
        setDialogIsOpen={setDialogIsOpen}
        type="risk"
      />
      {riskIncreaseEnabled && (
        <Box>
          <Typography variant="body1">
            Select the threats you’d like to receive risk increase notifications
            for.
          </Typography>
          <Box>
            <SelectAllWrapper>
              <CheckboxContainer>
                <StyledCheckbox
                  checked={selectedThreats.length === 4}
                  onChange={(e, checked) => {
                    if (checked) {
                      setSelectedThreats([
                        Disease_Type.RESP,
                        Disease_Type.COVID,
                        Disease_Type.RSV,
                        Disease_Type.FLU,
                      ])
                    } else {
                      setSelectedThreats([])
                    }
                  }}
                  indeterminate={!!(selectedThreats.length % 4)}
                  sx={{ padding: 0, marginRight: "8px", marginTop: "2px" }}
                />
              </CheckboxContainer>
              <Typography>Select all</Typography>
            </SelectAllWrapper>
          </Box>
          <ThreatsContainer>
            {ThreatLabels.map(label => (
              <RiskIncreaseLabel key={label.type} label={label} />
            ))}
          </ThreatsContainer>
        </Box>
      )}
      {riskIncreaseEnabled && (
        <>
          <SelectionSummary
            notificationType={notificationType}
            setLocationSelectorIsOpen={onOpenSelector}
          />
          <LocationSelectorDialog
            dialogIsOpen={locationSelectorIsOpen}
            setDialogIsOpen={onOpenSelector}
            title="Expert Insights"
            notificationType={notificationType}
          />
        </>
      )}
    </SettingsContainer>
  )
}

const RiskIncreaseLabel: React.FC<{ label: ThreatLabel }> = ({ label }) => {
  const { notificationType } = useNotificationTypeContext()
  const { selectedThreats, setSelectedThreats } =
    useNotificationContext(notificationType)

  if (!setSelectedThreats) return

  return (
    <StyledFormLabel
      label={label.element}
      control={<StyledCheckbox />}
      checked={selectedThreats.includes(label.type)}
      onChange={(e, checked) => {
        if (checked) {
          setSelectedThreats(curr => [...curr, label.type])
        } else {
          setSelectedThreats(curr => curr.filter(f => f !== label.type))
        }
      }}
      value={label.type}
    />
  )
}

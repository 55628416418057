import { Box, styled, Tab, Tabs, Typography } from "@mui/material"
import { useFlags } from "launchdarkly-react-client-sdk"
import type React from "react"
import { useState } from "react"
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { MainContent } from "../MainContent"
import { Container } from "../Settings/shared/SettingsContainer"
import TabPanel from "../TabsPanel"
import swaggerJson from "./layers_service_v1.30.0.swagger.json"
import { QuickstartDocumentaion } from "./Quickstart"

const StyledTabPanel = styled(TabPanel)(() => ({
  "& .MuiBox-root": {
    padding: 0,
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    maxWidth: "1000px",
  },
  display: "flex",
  placeContent: "center",
}))

const SwaggerPlacementContainer = styled("div")({
  display: "flex",
  marginTop: -40,
  ".information-container": {
    marginBottom: -40,
  },
  // hides the authorization section for in-browser use
  ".scheme-container, .try-out": {
    display: "none",
  },
})

export const Documentation = () => {
  const { showApiDocs } = useFlags()
  const [activeTab, setActiveTab] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setActiveTab(newTab)
  }

  function a11yProps(index: number) {
    return {
      id: `documentation-tab-${index}`,
      "aria-controls": `documentation-tabpanel-${index}`,
    }
  }

  if (!showApiDocs) {
    return (
      <MainContent
        headerProps={{
          title: "API Documentation",
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Coming Soon!</Typography>
        </Container>
      </MainContent>
    )
  }

  return (
    <MainContent
      headerProps={{
        title: "API Documentation",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", width: "fit-content" }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            aria-label="documentation tabs"
          >
            <Tab label="Quickstart" {...a11yProps(0)} />
            <Tab label="Layers API Reference" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <StyledTabPanel
          value={activeTab}
          index={0}
          ariaLabel={activeTab.toString()}
        >
          <QuickstartDocumentaion />
        </StyledTabPanel>
        <StyledTabPanel
          value={activeTab}
          index={1}
          ariaLabel={activeTab.toString()}
        >
          <Container
            sx={{
              display: "flex",

              justifyContent: "center",
            }}
          >
            <SwaggerPlacementContainer>
              <SwaggerUI spec={swaggerJson} />
            </SwaggerPlacementContainer>
          </Container>
        </StyledTabPanel>
      </Box>
    </MainContent>
  )
}

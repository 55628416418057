import { Typography, styled } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { extraColors } from "../../../../utils/theme"
import Button from "../../../Shared/Button"
import { SettingsContainer } from "../../shared/SettingsContainer"
import {
  FORECAST_RANGE_DEFAULT,
  useNotificationContext,
} from "../contexts/NotificationContext"
import { useNotificationTypeContext } from "../contexts/NotificationTypeContext"
import { EmailExampleDialog } from "../EmailExampleDialog"
import { SelectionSummary } from "../ExpertInsights/GlobalSettingsRadioGroup"
import { LocationSelectorDialog } from "../LocationSelector/LocationSelectorDialog"
import { SettingCheckbox } from "../SettingCheckbox"
import { ForecastRangeRadioGroup } from "./ForecastRangeRadioGroup"

const CheckboxRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: "start",
  marginLeft: 18,
  [theme.breakpoints.up("md")]: {
    alignSelf: "center",
    marginLeft: 8,
  },
}))

const AbsenteeismSettingsContainer = styled("div")({
  display: "grid",
  gap: 16,
  marginLeft: 26,
})

export const Absenteeism = () => {
  const { notificationType } = useNotificationTypeContext()
  const {
    assetsLoading,
    forecastedAbsenteeismEnabled,
    setForecastedAbsenteeismEnabled,
    historicalAbsenteeismEnabled,
    setHistoricalAbsenteeismEnabled,
    selectedAssets,
    setSelectedAssets,
    nonGlobalAssets,
    setDialogSelectedAssets,
    setForecastRange,
  } = useNotificationContext(notificationType)
  const [absenteeismEnabled, setAbsenteeismEnabled] = useState(
    historicalAbsenteeismEnabled || forecastedAbsenteeismEnabled
  )

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [locationSelectorIsOpen, setLocationSelectorIsOpen] = useState(false)

  useEffect(() => {
    // When neither checkbox is selected, then absenteeism as a whole
    // is no longer effectively selected, so we need to reset the top level setting,
    // the forecast range, and dialog/selected assets.
    if (
      !assetsLoading &&
      !historicalAbsenteeismEnabled &&
      !forecastedAbsenteeismEnabled
    ) {
      setAbsenteeismEnabled(false)
      setForecastRange?.(undefined)
      setDialogSelectedAssets?.([])
      setSelectedAssets?.([])
    }
  }, [
    assetsLoading,
    forecastedAbsenteeismEnabled,
    historicalAbsenteeismEnabled,
    setDialogSelectedAssets,
    setForecastRange,
    setSelectedAssets,
  ])

  useEffect(() => {
    if (historicalAbsenteeismEnabled || forecastedAbsenteeismEnabled) {
      setAbsenteeismEnabled(true)
    }
  }, [forecastedAbsenteeismEnabled, historicalAbsenteeismEnabled])

  const onOpenSelector = useCallback(
    (newState: boolean) => {
      if (newState && !selectedAssets?.length) {
        setSelectedAssets?.(nonGlobalAssets)
        setDialogSelectedAssets?.(nonGlobalAssets)
      }
      setLocationSelectorIsOpen(newState)
    },
    [
      setDialogSelectedAssets,
      nonGlobalAssets,
      selectedAssets?.length,
      setSelectedAssets,
    ]
  )

  const onSetAbsenteeism = useCallback(
    (checked: boolean) => {
      setAbsenteeismEnabled(checked)

      // When changing absenteeism from off to on, set the forecast range
      // to the default value and enable historical absenteeism notifications
      if (checked) {
        setHistoricalAbsenteeismEnabled?.(true)
        setForecastRange?.(FORECAST_RANGE_DEFAULT)
        setSelectedAssets?.(nonGlobalAssets)
        setDialogSelectedAssets?.(nonGlobalAssets)
      } else {
        // When chaing them from on to off, deselect both absenteeism notification types
        // and clear out the forecast range and selected assets
        setHistoricalAbsenteeismEnabled?.(false)
        setForecastedAbsenteeismEnabled?.(false)
        setForecastRange?.(undefined)
        setSelectedAssets?.([])
        setDialogSelectedAssets?.([])
      }
    },
    [
      nonGlobalAssets,
      setDialogSelectedAssets,
      setForecastRange,
      setForecastedAbsenteeismEnabled,
      setHistoricalAbsenteeismEnabled,
      setSelectedAssets,
    ]
  )

  return (
    <SettingsContainer title="Absenteeism Notifications">
      <CheckboxRow>
        <SettingCheckbox
          settingEnabled={absenteeismEnabled || false}
          customSettingFn={onSetAbsenteeism}
          title={<>Receive absenteeism forecasts for your locations.</>}
        />
        <ButtonStyled variant="text" onClick={() => setDialogIsOpen(true)}>
          <Typography variant={"body1Bold"} color={extraColors.purpleMiddle}>
            View example
          </Typography>
        </ButtonStyled>
      </CheckboxRow>
      <EmailExampleDialog
        dialogIsOpen={dialogIsOpen}
        setDialogIsOpen={setDialogIsOpen}
        type="absenteeism"
      />
      {absenteeismEnabled && (
        <>
          <AbsenteeismSettingsContainer>
            <SettingCheckbox
              settingEnabled={historicalAbsenteeismEnabled || false}
              setSettingEnabled={setHistoricalAbsenteeismEnabled}
              title="Notify me when forecasts are expected to be worse than historic averages for my industry."
            />
            <SettingCheckbox
              settingEnabled={forecastedAbsenteeismEnabled || false}
              setSettingEnabled={setForecastedAbsenteeismEnabled}
              title="Notify me whenever there is an increase in forecasted absenteeism."
            />
          </AbsenteeismSettingsContainer>
          <ForecastRangeRadioGroup />
          <SelectionSummary
            notificationType={notificationType}
            setLocationSelectorIsOpen={onOpenSelector}
          />
          <LocationSelectorDialog
            dialogIsOpen={locationSelectorIsOpen}
            setDialogIsOpen={onOpenSelector}
            title="Absenteeism"
            notificationType={notificationType}
          />
        </>
      )}
    </SettingsContainer>
  )
}

import { Navigate } from "react-router"
import { usePermissions } from "../../hooks/usePermissions"
import { ROUTES } from "../../utils/constants"
import { MainContent } from "../MainContent"
import { AssetServiceOrganizations } from "./AssetServiceManagement/AssetServiceOrganizations"

export const AdminSettings = () => {
  const permissions = usePermissions()

  if (!permissions.isLoading && !permissions.organizations.read) {
    return <Navigate replace to={ROUTES.BASE} />
  }

  return (
    <MainContent
      headerProps={{
        title: "Account Management",
      }}
    >
      <AssetServiceOrganizations />
    </MainContent>
  )
}

import { useContext } from "react"
import { USE_ZITADEL } from "../env"
import ZitadelContext from "./zitadel-context"

const useZitadel = () => {
  const context = useContext(ZitadelContext)
  if (!context && USE_ZITADEL) {
    throw new Error("You forgot to wrap your component in <ZitadelProvider>.")
  }
  return context
}

export default useZitadel

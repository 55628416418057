import { useMemo } from "react"
import useAuth from "../utils/auth"
import { claims } from "../utils/constants"
import { useOrganization } from "./useOrganization"

export function useUser() {
  const { user } = useAuth()
  const org = useOrganization()
  return useMemo(() => {
    if (!user) {
      return undefined
    }

    return {
      name: user[claims.user] as string,
      roles: user[claims.roles] as string[],
      userId: user.email,
      orgId: user.org_id as string,
      orgName: org?.name,
      email: user.email,
    }
  }, [user, org])
}

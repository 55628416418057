import { useEffect, useState } from "react"
import useAuth from "../utils/auth"

/**
 * Hook to keep fresh access token for use in components.
 */
export const useAccessToken = () => {
  const auth = useAuth()
  const [accessToken, setAccessToken] = useState<string | null>(null)
  useEffect(() => {
    const fetchToken = async () => {
      const token = await auth.getAccessTokenSilently()
      setAccessToken(token)
    }
    fetchToken().catch(console.error)
  }, [auth])
  return accessToken
}

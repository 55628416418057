import { styled, Typography } from "@mui/material"
import NoSsr from "@mui/material/NoSsr"
import type { PopperProps } from "@mui/material/Popper"
import Popper from "@mui/material/Popper"
import {
  useAxisTooltip,
  useSvgRef,
  useXScale,
  useYScale,
  type UseAxisTooltipReturnValue,
} from "@mui/x-charts-pro"
// import type { ScaleBand } from "@mui/x-charts-vendor/d3-scale"
import type { Dispatch, SetStateAction } from "react"
import * as React from "react"
import { extraColors } from "../../utils/theme"

interface PointerState {
  isActive: boolean
  isMousePointer: boolean
  pointerHeight: number
}

export type AskHighlightedItem =
  UseAxisTooltipReturnValue<"line">["seriesItems"][number]

const isAskHighlightedItem = (item: unknown): item is AskHighlightedItem =>
  typeof item === "object" &&
  item !== null &&
  "color" in item &&
  "formattedLabel" in item &&
  "formattedValue" in item &&
  "value" in item &&
  "markType" in item &&
  item.markType === "line"

function useFindNearestPoint(
  setHighlightedItem: Dispatch<SetStateAction<AskHighlightedItem | null>>
): PointerState & Pick<PopperProps, "popperRef" | "anchorEl"> {
  const svgRef = useSvgRef()
  const yScale = useYScale<"linear">()
  const xScale = useXScale<"time">()
  const axisTooltip = useAxisTooltip()

  // Use a ref to avoid rerendering on every mousemove event.
  const [pointer, setPointer] = React.useState<PointerState>({
    isActive: false,
    isMousePointer: false,
    pointerHeight: 0,
  })

  React.useEffect(() => {
    const element = svgRef.current
    if (element === null) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {}
    }

    const handleOut = (event: PointerEvent) => {
      setHighlightedItem(null)
      if (event.pointerType !== "mouse") {
        setPointer(prev => ({
          ...prev,
          isActive: false,
        }))
      }
    }

    const handleEnter = (event: PointerEvent) => {
      setPointer({
        isActive: true,
        isMousePointer: event.pointerType === "mouse",
        pointerHeight: event.height,
      })
    }

    const handleMove = (event: PointerEvent) => {
      const mouseY = yScale.invert(event.layerY)
      const nearest = axisTooltip?.seriesItems
        .filter(s => s.markType === "line")
        .reduce(
          (prev, curr) => {
            if (curr.markType !== "line") return prev
            return Math.abs(Number(curr.value ?? 0) - mouseY) <
              Math.abs(Number(prev.value ?? 0) - mouseY)
              ? curr
              : prev
          },
          { value: Infinity } as AskHighlightedItem
        )
      if (
        !nearest ||
        typeof axisTooltip?.axisValue !== "number" ||
        typeof nearest.value !== "number" ||
        !isAskHighlightedItem(nearest)
      ) {
        return
      }
      setHighlightedItem(curr => {
        if (curr?.value === nearest.value && curr.seriesId === nearest.seriesId)
          return curr
        return nearest
      })
    }

    element.addEventListener("pointerenter", handleEnter)
    element.addEventListener("pointerup", handleOut)
    element.addEventListener("pointerout", handleOut)
    element.addEventListener("pointermove", handleMove)

    return () => {
      element.removeEventListener("pointerenter", handleEnter)
      element.removeEventListener("pointerup", handleOut)
      element.removeEventListener("pointerout", handleOut)
      element.removeEventListener("pointermove", handleMove)
    }
  }, [
    axisTooltip?.axisValue,
    axisTooltip?.seriesItems,
    setHighlightedItem,
    svgRef,
    xScale,
    yScale,
  ])

  return {
    ...pointer,
  }
}

const TooltipContent = styled("div")(({ theme }) => ({
  backgroundColor: extraColors.subtle,
  padding: theme.spacing(1),
  borderRadius: 2,
  boxShadow: theme.shadows[3],
  marginBottom: "6px",
  display: "grid",
  gap: 8,
  justifyContent: "center",
  alignItems: "center",
  justifyItems: "center",
  // add a triangle to the bottom center of the tooltip
  "&::before": {
    content: '""',
    position: "absolute",
    bottom: "0px",
    left: "calc(50% - 8px)",
    width: 0,
    height: 0,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: `8px solid ${extraColors.subtle}`,
  },
}))

const ColorSquare = styled("div")(({ color }) => ({
  width: 12,
  height: 12,
  borderRadius: 2,
  backgroundColor: color,
  display: "inline-block",
  verticalAlign: "sub",
}))

export function AskChartTooltip({
  setHighlightedItem,
  highLightedItem,
}: {
  highLightedItem: AskHighlightedItem | null
  setHighlightedItem: Dispatch<SetStateAction<AskHighlightedItem | null>>
}) {
  useFindNearestPoint(setHighlightedItem)
  const yScale = useYScale<"linear">()
  const xScale = useXScale<"time">()
  const axisTooltip = useAxisTooltip()
  const svgRef = useSvgRef()
  if (
    !axisTooltip ||
    !highLightedItem ||
    typeof axisTooltip.axisValue !== "number" ||
    typeof highLightedItem.value !== "number"
  ) {
    // No data to display
    return null
  }

  const svgXPosition = xScale(axisTooltip.axisValue)
  const svgYPosition = yScale(highLightedItem.value)
  const tooltipPosition = {
    x: (svgRef.current?.getBoundingClientRect().left ?? 0) + svgXPosition,
    y: (svgRef.current?.getBoundingClientRect().top ?? 0) + svgYPosition,
  }

  return (
    <NoSsr>
      <Popper
        sx={{
          pointerEvents: "none",
          zIndex: theme => theme.zIndex.modal,
        }}
        open
        anchorEl={{
          getBoundingClientRect: () => ({
            x: tooltipPosition.x,
            y: tooltipPosition.y,
            top: tooltipPosition.y,
            left: tooltipPosition.x,
            right: tooltipPosition.x,
            bottom: tooltipPosition.y,
            width: 0,
            height: 0,
            toJSON: () => "",
          }),
        }}
        placement="top"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
        ]}
      >
        <TooltipContent>
          <Typography variant="tiny" textTransform={"uppercase"}>
            <ColorSquare color={highLightedItem.color} />{" "}
            {highLightedItem.formattedLabel}
          </Typography>

          <Typography variant="tiny" color={extraColors.medium}>
            {axisTooltip.axisFormattedValue}
          </Typography>

          <Typography variant="small1Bold">
            {highLightedItem.formattedValue}
          </Typography>
        </TooltipContent>{" "}
      </Popper>
    </NoSsr>
  )
}

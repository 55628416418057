import { Typography } from "@mui/material"
import { extraColors } from "../../utils/theme"

export const AskFinePrint = () => {
  return (
    <Typography
      component="span"
      display="block"
      variant="small1"
      color={extraColors.hint}
    >
      Navigator is in Beta release for testing. This response is generated, in
      part, with the use of an experimental AI architecture. Please check all
      important information.
    </Typography>
  )
}

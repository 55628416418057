import { ButtonBase, styled, Typography } from "@mui/material"
import { isAlertLevel } from "@phc/common"
import { GLOBAL_GEOTAG } from "../../../../../common/src/constants"
import SvgGlobe from "../../../assets/svg-components/Globe"
import { useMapContext } from "../../../contexts/MapContext"
import { extraColors } from "../../../utils/theme"
import { useCapServiceContext } from "../../CriticalThreats/capServiceContext"
import { AlertClusterMarker } from "../Mapbox/AlertMarker"
import { getMapBreakpoint } from "../Shared"

const GlobeContainer = styled(ButtonBase)(({ theme }) => ({
  position: "absolute",
  pointerEvents: "all",
  padding: 12,
  bottom: 0,
  left: 0,

  [getMapBreakpoint(theme)]: {
    bottom: "unset",
    left: "unset",
    top: 0,
    right: 0,
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 50,
    padding: 0,
  },
}))

const ResponsiveGlobe = styled(SvgGlobe)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: 60,
    height: 60,
  },
}))

const ResponsiveLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.small1Bold,
  position: "absolute",
  paddingTop: 24,
  color: extraColors.purpleMiddle,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
})) as typeof Typography

export const GlobalAlerts = () => {
  const { useAlerts } = useCapServiceContext()
  const { data } = useAlerts()
  const { alertFilters, setSelectedLocationId, setOpenAlertDrawer } =
    useMapContext()
  const globalAlerts = data?.filter(
    alert =>
      isAlertLevel(alert.alertLevel) &&
      alertFilters.includes(alert.alertLevel) &&
      alert.geotags?.some(
        geotag => geotag.locationId === GLOBAL_GEOTAG.locationId
      )
  )
  if (!globalAlerts?.length) return null
  const alertIds = globalAlerts.map(alert => alert._id)

  return (
    <GlobeContainer
      onClick={e => {
        e.stopPropagation()
        setSelectedLocationId(GLOBAL_GEOTAG.locationId)
        setOpenAlertDrawer(true)
      }}
      disableRipple
    >
      <ResponsiveGlobe />
      <AlertClusterMarker
        disableClick
        alertIds={alertIds}
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
        }}
      />
      <ResponsiveLabel>Global Alerts</ResponsiveLabel>
    </GlobeContainer>
  )
}
